import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import ComponentToPrint from './ComponentToPrint';
import { printRequest } from '../redux/actions/itemData.action';
import { printerStyle } from './utils';

function mapStateToProps(state) {

}

const mapDispatchToProps = {
  printRequest
};


class QuickBilling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: props.products.map(product => ({
        ...product,
        quantity: 1, // Default quantity
      })),
      printView: false
    };
  }

  handleIncrement = index => {
    this.setState(prevState => {
      const updatedProducts = [...prevState.products];
      updatedProducts[index].quantity++;
      return { products: updatedProducts };
    });
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.products !== this.props.products) {
      this.setState({
        products: this.props.products.map(product => ({
          ...product,
          quantity: 1, // Default quantity
        })),
      })
    }
  }

  handleDecrement = index => {
    this.setState(prevState => {
      const updatedProducts = [...prevState.products];
      if (updatedProducts[index].quantity > 1) {
        updatedProducts[index].quantity--;
      }
      return { products: updatedProducts };
    });
  };

  calculateTotal = () => {
    const { products } = this.state;
    let total = 0;
    products.forEach(product => {
      total += product.price * product.quantity;
    });
    return total.toFixed(2);
  };

  handlePrintBill = () => {
    const printContents = document.getElementById('print-bill').innerHTML;
    const printerName = "Microsoft Print to PDF";
    const escapedHtmlContent = printContents.replace(/"/g, '\\"')
    this.props.printRequest({htmlContents: escapedHtmlContent, printerName});
  };

  render() {
    const { products, printView } = this.state;

    return (
      <div className="container">
        <h2>Billing</h2>
        <table className="table">
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>Subtotal</th>
            </tr>
          </thead>
          <tbody>
            {products?.map((product, index) => (
              <tr key={index}>
                <td>{product.name}</td>
                <td>${product.price.toFixed(2)}</td>
                <td>
                  <button
                    className="btn btn-sm btn-primary mr-2"
                    onClick={() => this.handleDecrement(index)}
                  >
                    -
                  </button>
                  {product.quantity}
                  <button
                    className="btn btn-sm btn-primary ml-2"
                    onClick={() => this.handleIncrement(index)}
                  >
                    +
                  </button>
                </td>
                <td>${(product.price * product.quantity).toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="total">
          <strong>Total: ${this.calculateTotal()}</strong>
        </div>
        <button className="btn btn-primary" id='printButton' onClick={this.handlePrintBill}>Print Bill</button>
        {<><div id="print-bill" style={{ display: "none" }}>
          <ComponentToPrint products={products} discountPercentage={10} />
        </div></>
        }
      </div>
    );
  }
}

export default connect(
  null, mapDispatchToProps
)(QuickBilling);
