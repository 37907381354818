import { call, put, takeLatest } from "redux-saga/effects";
import { GET_ITEM_REQUEST, PRINT_REQUEST, getItemRequestFailure, getItemRequestSuccess, printRequestFailure, printRequestSuccess } from "../actions/itemData.action";
import { getItemSchema } from "../schemas/item_data.schema";
import client from "../../../../graphql/client";
import { sagaHandle } from "../../../Utils/sagaDataHandling";
import { Mutation, Query } from "../../../../api/graphql.config";
import { printSchema } from "../schemas/print.schema";

export function* getItemRequestSaga() {
    try {
        yield call(
            sagaHandle,
            Query,
            getItemSchema(),
            getItemRequestSuccess,
            getItemRequestFailure
        )
    } catch (error) {
        console.log(error)
    }
}

export function* printRequestSaga({ payload }) {
    try {
        yield call(
            sagaHandle,
            Mutation,
            printSchema(payload),
            printRequestSuccess,
            printRequestFailure
        )
    } catch (error) {
        console.log(error)
    }
}

export default function* userItemDataSagas() {
    yield takeLatest(GET_ITEM_REQUEST, getItemRequestSaga)
    yield takeLatest(PRINT_REQUEST, printRequestSaga)
}