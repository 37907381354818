import { GET_PRINTER_DETAILS_REQUEST, GET_PRINTER_DETAILS_REQUEST_FAILURE, GET_PRINTER_DETAILS_REQUEST_SUCCESS } from "../actions/settings.action";


const initialState = {
    printerDetails: null,
    isLoading: false,
    errorMessage: null
}

export const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
        
        case GET_PRINTER_DETAILS_REQUEST:
            return {
                ...state,
                isLoading: true,
                printerDetails: null,
            }
            break;
        case GET_PRINTER_DETAILS_REQUEST_SUCCESS:
            console.log(action.payload)
            return {
                ...state,
                isLoading: false,
                printerDetails: {...action.payload}
            }
            break;
        case GET_PRINTER_DETAILS_REQUEST_FAILURE:
            console.log(action)
            return {
                ...state,
                isLoading: false,
                printerDetails: null,
                errorMessage: action.error.error
            }
            break;

        default:
            return {
                ...state
            }
            break;
    }
}