import React, { Component } from 'react';

export const MessageBanner = (props) => {
    const {messageType , messageContent , closeBtn} = props;
   const iconType =  messageType === "success" ? "check" : messageType === "warning" ? "exclamation" : messageType
    return <>
       <div class={"wrapper-"+ messageType}>
        <div class="card">
          <div class="icon"><i class={"fas fa-"+iconType+"-circle"}></i></div>
          <div class="subject">
            {/* <h3>{messageType.charAt(0).toUpperCase() + messageType.slice(1)}</h3> */}
            <p>{messageContent}</p>
          </div>
          <div class="icon-times" onClick={closeBtn}><i class="fas fa-times"></i></div>
        </div>
      </div>
    </>
}