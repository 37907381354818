export const uploadServiceDataSchema = (payload) =>{
    console.log(payload)
    const excelDataString = payload.input.map((item) => {
        return `
          {
            product_name: "${item.product_name}"
            hsn_sac: "${item.hsn_sac}"
            rate_per_count: ${parseInt(item.rate_per_count)}
            gstin_percentage: ${parseFloat(item.gstin_percentage)}
            district: "${item.district}"
            season:  "${item.season}"
            year: ${parseInt(item.year)}
          }
        `;
      }).join(',\n');

      const schema = `
      mutation uploadServiceDataExcel {
        uploadServiceDataExcel(input: [${excelDataString}]) {
            message
    updatedServiceData { 
      id
     product_name
      hsn_sac
      rate_per_count
      gstin_percentage
      district
      season
      year
    }
       }
     }`

     return schema
}
