export const deleteFirmDataSchema = (payload) => `
mutation DeleteFirmMaster {
    deleteFirmMaster(id: ${payload.id}) {
    message
    updatedFirmData{
      id
       firm_name
    firm_address
    owner_name
    pincode
    gstin_number
    state
    state_code
    bank_name
    bank_account_number
    bank_branch
    bank_ifsc
    pan_company
    role
    }
   }
 }
`