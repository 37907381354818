import { gql } from "apollo-boost";

export const buildUserLoginSchema = ({email, password}) => `
mutation {
  login(res_owner_emailid: "${email}", res_password: "${password}") {
    res_name
    res_owner_name
    res_owner_emailid
    res_owner_phone_number
    res_mac_id
    address
    zip_code
    city
    state
    role
    token
  }
}`