import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { userProfileModuleReducer } from "../modules/UserProfile/redux/reducers";
import { loginModuleReducer } from "../modules/Authentication/Login/redux/reducers";
import { registerModuleReducer } from "../modules/Authentication/Registration/redux/reducers";
import { headerModuleReducer } from "../modules/Header/redux/reducers";
import { itemModuleReducer } from "../modules/RestroBilling/redux/reducers";
import { settingModuleReducer } from "../modules/RestroSettings/redux/reducers";
import { masterDataModuleReducer } from "../modules/InvoiceCreation/redux/reducers";
const RESET= 'RESET';

const combinedReducers = combineReducers({
    userProfileModuleReducer,
    form: formReducer,
    loginModuleReducer,
    registerModuleReducer,
    headerModuleReducer,
    itemModuleReducer,
    settingModuleReducer,
    masterDataModuleReducer
})

export const rootReducer = function (state , action) {
    if (action.type === RESET) {
        state = undefined;
    }
    return combinedReducers(state, action);
}