import React, { Component } from 'react';
import { Nav, Container } from 'react-bootstrap';
import gstStates from 'gst-states';
import GenricTableDisplay from './GenricTableDisplay';
import { connect } from 'react-redux';
import { getFirmDataRequest, updateFirmDataRequest } from '../../redux/actions/firmData.action';
import { getServiceDataRequest } from '../../redux/actions/serviceData.action';
import FirmDataEditDisaplay from './FirmDataEditDisaplay';
import LoadingIndicator from '../../../Form/LoadingIndicator/LoadingIndicator';
import AdminDataEditDisplay from './AdminDataEditDisplay';
import withRouter from '../../../Utils/wthRouter';

function mapStateToProps(state) {

  const {  serviceData ,firmData,updateService ,isLoading} = state.masterDataModuleReducer.masterDataReducer;

  return { serviceData ,firmData,updateService , isLoading};
}

const mapDispatchToProps = {
  getFirmDataRequest,
  getServiceDataRequest,
  updateFirmDataRequest
};
// Sample report components
const reports = {
  admin: (data) => (<AdminDataEditDisplay  initialValues={data} editableInt={false}  />),
  buyers: (data, header) => (<GenricTableDisplay data={data} headers={header} fileName={'Buyers_Detail'}/>),
  serviceData: (data, header) => (<GenricTableDisplay data={data} headers={header} fileName={'Service_Data_Detail'}/>),
};

class MasterDataDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentReport: null,  // Default report,
      currentReportName: ''
    };
  }

  handleNavClick = (report) => {
    const { serviceData, firmData } = this.props;
    let reportComponent;
    const filterFirmData = firmData?.filter(e => e.role === 'buyer') ? firmData?.filter(e => e.role === 'buyer') : []
    if (report === 'buyers') {
      reportComponent = reports.buyers(filterFirmData, filterFirmData.length>0 ? this.generateHeaders(filterFirmData[0]) : []);
    } else if (report === 'serviceData') {
      reportComponent = reports.serviceData(serviceData, serviceData.length>0 ? this.generateHeaders(serviceData[0]) : []);
    }else if (report === 'admin'){
      reportComponent = reports.admin(firmData?.filter(e => e.role === 'admin')[0]);
    }else {
      reportComponent = reports[report]();
    }
  
    this.setState({ currentReport: reportComponent , currentReportName: report});
  };
  generateHeaders = (data) => {
    const keys = Object.keys(data);
    // Exclude keys like __typename and role
    const filteredKeys = keys.filter(key => key !== '__typename' && key !== 'role');
    return filteredKeys.map(key => ({
      key,
      label: key.charAt(0).toUpperCase() + key.slice(1)
    }));
  };
  componentDidUpdate =(prevProps)=>{
    if (prevProps.firmData !== this.props.firmData && this.props.firmData && !this.state.currentReport) {
      this.handleNavClick('admin')
    }
    if (prevProps.firmData !== this.props.firmData && this.props.firmData && this.state.currentReportName === 'buyers') {
      this.handleNavClick('buyers')
    }
    if (prevProps.serviceData !== this.props.serviceData && this.props.serviceData && this.state.currentReportName === 'serviceData') {
      this.handleNavClick('serviceData')
    }
    
  }

  componentDidMount = () => {
    this.props.getFirmDataRequest();
    this.props.getServiceDataRequest();
   
  }
  render() {
    const { currentReport } = this.state;

    return (
      <>{this.props.isLoading && <LoadingIndicator isOverlay={true} pageLoader={true} />}
      <div className="row">
        <div className="col-3" style={{ borderRight: '1px solid #dee2e6', height: '80vh', overflowY: 'auto' }}>
          <Container>
            <Nav className="flex-column">
              <Nav.Link
                onClick={() => this.handleNavClick('admin')}
                style={{ backgroundColor: '#f8f9fa', borderBottom: '1px solid #dee2e6' }}
              >
                Admin Data
              </Nav.Link>
              <Nav.Link
                onClick={() => this.handleNavClick('buyers')}
                style={{ backgroundColor: 'white', borderBottom: '1px solid #dee2e6' }}
              >
                Buyers Data
              </Nav.Link>
              <Nav.Link
                onClick={() => this.handleNavClick('serviceData')}
                style={{ backgroundColor: '#f8f9fa', borderBottom: '1px solid #dee2e6' }}
              >
                Service Data
              </Nav.Link>
            </Nav>
          </Container>
        </div>
        <div className="col-9">
          <Container>
            {currentReport}
          </Container>
        </div>
      </div>
      </>
    );
  }
}

export default connect(
  mapStateToProps, mapDispatchToProps
)(withRouter(MasterDataDisplay));
