import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { validateAmount, validateIsRequired } from '../../../Form/GenericFields/validation';
import { FormWrapper } from '../../../Form/GenericFields/GenericWrappers';
import GenericFields from '../../../Form/GenericFields/GenericFields';
import { change, initialize, reduxForm } from 'redux-form';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';

function mapStateToProps(state) {

    const  {payment_type, utr_number, amount,created_date }  = state.form.updateInvoiceStatus?.values || [];
  
    return { payment_type, utr_number, amount,created_date  };
  }
  


const UpdateStatusDisplay = ({handleSubmit, initialValues,invoiceRowtoUpdate, paymentModeData ,paymentTypeData, payment_type, utr_number, created_date, dispatch}) => {
    
    //const [selectedPaymentType, setSelectedPaymentType] = useState(initialValues.payment_type || ''); // Initialize selected payment type from initialValues

    // Function to handle change in payment type
    const handlePaymentTypeChange = (event) => {
        const paymentType = payment_type;
      //  setSelectedPaymentType(paymentType); // Update selected payment type
        updateInitialValues(paymentType); // Update initial values based on selected payment type
    };

    useEffect(()=>{
        handlePaymentTypeChange();
    }, [payment_type])

    // Function to update initial values based on selected payment type
    const updateInitialValues = (paymentType) => {
        let initialAmount = ''; // Default initial amount

        if (paymentType?.value === 'Settled') {
            initialAmount = invoiceRowtoUpdate.subTotalNet - invoiceRowtoUpdate.sumPartialAmount; // Set initial amount to total amount if payment type is 'Settled'
            

        // Update initial values object
        const updatedInitialValues = {
            ...initialValues,
            utr_number, created_date ,
            payment_type: paymentType, // Update payment type
            amount: initialAmount // Update amount field
        };

        // Dispatch initialize action to update initial values in Redux Form state
        dispatch(initialize('updateInvoiceStatus', updatedInitialValues));
    }
    };

    return (
        <div style={{padding: '0'}}>
               <FormWrapper showNav={false} parrentTab={""} childrens={[]} noPadding={true}>
                <form onSubmit={handleSubmit}>
                <div className="col-md-6">
                            <div className="form-outline mb-4">
                                <GenericFields isDisabled placeholder="" name="invoiceId" label="" is className="form-control" value={invoiceRowtoUpdate.invoiceId? true : false} validate={[ validateIsRequired ,validateAmount]} />
                            </div>
                            </div>
                        <div className="col-md-6 mb-4">
                            <div className="form-outline">
                            <GenericFields placeholder="Enter UTR Number" name="utr_number" label="UTR Number" className="form-control"  validate={[ validateIsRequired ]} />
 </div>
                        </div>   
                        <div className="col-md-6 mb-4">
                            <div className="form-outline">
                                <GenericFields name="payment_type" label="Type of Payment"  type='select' options={paymentTypeData} isDisabled={initialValues.amount}  className="form-control" validate={[validateIsRequired]} />
                            </div>
                        </div>                       
                <div className="col-md-6">
                            <div className="form-outline mb-4">
                                <GenericFields placeholder="Enter Ammount" name="amount" label="Ammount" className="form-control" isDisabled={payment_type==='Settled'} validate={[ validateIsRequired ,validateAmount]} />
                            </div>
                            </div>
                            <div className="col-md-6">
                            <div className="form-outline mb-4">
                                <GenericFields name="created_date" label="Recieved Date" className="form-control" type={"date"}  validate={[validateIsRequired]} />
                            </div>
                            </div>
                            <p>Total ammount :{invoiceRowtoUpdate.subTotalNet} </p>
                            <Button variant="info" size="sm" onClick={handleSubmit}>
                            Update Payment
                          </Button>{' '}
                        
                </form>
                </FormWrapper>
        </div>
    );
};
export default connect(mapStateToProps,null)(reduxForm({
    form: 'updateInvoiceStatus', // Provide a unique form name
})(UpdateStatusDisplay));