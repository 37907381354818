// src/graphql/queries.js

import { gql } from '@apollo/client';

export const getPrinterDetailsSchema = () => `
  query {
    getPrinters {
      name
    portName
    driverName
    printProcessor
    datatype
    status
    statusNumber
    attributes
    priority
    defaultPriority
    averagePPM
  }
  }
`;
