import React, { Component } from 'react';
import { Table, Form, Button } from 'react-bootstrap';
import { addPaymentRequest, getPDFBase64Request } from '../../redux/actions/invoiceData.action';
import DynamicModal from '../../../Form/GenericFields/DynamicModal';
import IframePreview from '../CreateInvoice/IframePreview';
import { connect } from 'react-redux';
import InvoicePreview from '../CreateInvoice/InvoicePreview';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import UpdateStatusDisplay from './UpdateStatusDisplay';

function mapStateToProps(state) {

  const { base64InvoicePDF } = state.masterDataModuleReducer.masterDataReducer;

  return { base64InvoicePDF };
}

const mapDispatchToProps = {
  getPDFBase64Request,
  addPaymentRequest
};


class InvoiceTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      sortBy: 'status',
      sortOrder: 'asc',
      showModal: false,
      dataModal: null,
      logo: '',
      showStatusInput: '',
      showUpdatePaymentModal: false,
      invoiceRowtoUpdate: null,
      paymentTypeData: ["Partial","Settled","Full&Final"].map(type => ({
        value: type,
        label: type,
    })),
    paymentModeData: ["NEFT","RTGS"].map(mode => ({
      value: mode,
      label: mode,
  }))
    };
  }

  handleClose = () => {
    this.setState({ showModal: false ,showUpdatePaymentModal: false})
  }

  handleSearchChange = (event) => {
    this.setState({ searchTerm: event.target.value });
  };

  handleSort = (field) => {
    const { sortBy, sortOrder } = this.state;
    if (sortBy === field) {
      this.setState({ sortOrder: sortOrder === 'asc' ? 'desc' : 'asc' });
    } else {
      this.setState({ sortBy: field, sortOrder: 'asc' });
    }
  };

  showInvoice = (invoiceId) => {
    const { invoiceData } = this.props;
    const invoiceDataShow = invoiceData.filter(e => e.invoiceId === invoiceId)[0]
    const dataModal = { ...invoiceDataShow, logo: this.state.logo }
    this.setState({ showModal: true, dataModal: dataModal })
  }
  componentDidMount = () => {
    this.getBase64FromUrl('asset/logo/logo.jpg')
      .then(base64 => {
        this.setState({ logo: base64 });
      })
      .catch(error => console.error(error));

  }


  getBase64FromUrl = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.base64InvoicePDF !== this.props.base64InvoicePDF) {
      this.setState({ showModal: true })
    }
  }

  updateStatusInvoice = (invoiceId) => {
    // Update status logic
    this.setState((prevState) => ({
      showStatusInput: prevState.showStatusInput === invoiceId ? null : invoiceId,
    }));
  };

  handlePaymentUpdate = (invoiceRow) => {
    this.setState({showUpdatePaymentModal: true,
      invoiceRowtoUpdate: invoiceRow})
  };

  handleInputChange = (formdata)=>{
    formdata['payment_type'] = formdata.payment_type.value;
    console.log("formdata : ",formdata)
    this.props.addPaymentRequest(formdata);
    this.handleClose();
  }

  getStatusDotClass(status) {
    switch (status) {
      case 'Settled':
        return 'status-settled';
      case 'Partial':
        return 'status-partial';
      default:
        return 'status-created';
    }
  }

  render() {
    const { invoiceData } = this.props;
    const { searchTerm, 
      sortBy, 
      sortOrder, 
      logo, showModal, dataModal, showStatusInput,invoiceRowtoUpdate,showUpdatePaymentModal ,paymentModeData ,paymentTypeData } = this.state;

    // Filter and sort invoiceData based on search term, sortBy, and sortOrder
    const filteredInvoices = Array.isArray(invoiceData)
      ? invoiceData.filter((invoice) =>
        invoice.invoiceId.toLowerCase().includes(searchTerm.toLowerCase())
      )
      : [];

    // Apply sorting if sortBy field is specified
    if (sortBy) {
      filteredInvoices.sort((a, b) => {
        const valueA = a[sortBy];
        const valueB = b[sortBy];
        if (sortOrder === 'asc') {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      });
    }

   
    return (
      <div>
        <Form className="mb-3">
          <Form.Control
            type="text"
            placeholder="Search by Invoice ID"
            value={searchTerm}
            onChange={this.handleSearchChange}
          />
        </Form>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th onClick={() => this.handleSort('invoiceId')}>Invoice ID</th>
              <th onClick={() => this.handleSort('buyerFirmName')}>Buyer Name</th>
              <th onClick={() => this.handleSort('buyerFirmState')}>State</th>
              <th onClick={() => this.handleSort('status')}>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredInvoices?.map((invoice, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td>{invoice.invoiceId}</td>
                  <td>{invoice.buyerFirmName}</td>
                  <td>{invoice.buyerFirmState}</td>
                  <td>
                  <span className={`status-dot ${this.getStatusDotClass(invoice.status)}`}></span>
                  {"  "}
                    {invoice.status}
                    
                    
                    </td>
                  <td>
                    <Button variant="info" size="sm" onClick={() => this.showInvoice(invoice.invoiceId)}>
                      Show Invoice
                    </Button>{' '}
                    <Button variant="primary" size="sm" onClick={() => this.updateStatusInvoice(invoice.invoiceId)}>
                      Update Status
                      {showStatusInput === invoice.invoiceId ? <FaArrowUp /> : <FaArrowDown />}
                    </Button>
                  </td>
                </tr>
                {showStatusInput === invoice.invoiceId && (
                  // 
                  //     <UpdateStatusDisplay />
                  //     </td>
                  // </tr>
                  <tr>
                    <td colSpan="10">

                      <Table>
                        <thead>
                          <tr>
                            <th >Srn</th>
                            <th >Payment Mode</th>
                            <th >Payment Type</th>
                            <th>Ammount</th>
                            <th>Recieved Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoice.paymentDetails.length > 0 ? invoice.paymentDetails.map((item, index) => (
                            <>
                              <tr>
                                <td>{index + 1}</td>
                                <td>{item.utr_number}</td>
                                <td>{item.payment_type}</td>
                                <td>{item.amount}</td>
                                <td>{item.created_date}</td>
                              </tr>
                            </>
                          )) : <p>Not updated the payment yet</p>}
                         {invoice.status !== 'Settled' && <Button variant="info" size="sm" onClick={() => this.handlePaymentUpdate(invoice)}>
                            Update Payment
                          </Button>}
                        </tbody>

                      </Table>
                    </td>
                  </tr>


                )}
              </React.Fragment>
            ))}
          </tbody>
        </Table>

        <DynamicModal
          showModal={showModal}
          handleClose={this.handleClose}
          title="Invoice Print"
          size='lg'
          body={
            <div >
              {dataModal && <div>
                <InvoicePreview invoiceData={dataModal} modal={true} />
              </div>}
            </div>
          }
          showPrintButton={true}
          showButtons={false} // or false, depending on your requirement
        />
         <DynamicModal
          showModal={showUpdatePaymentModal}
          handleClose={this.handleClose}
          title="Update Invoice"
          size='lg'
          body={
            <div >
              {invoiceRowtoUpdate && <div>
                <UpdateStatusDisplay 
                  invoiceRowtoUpdate={invoiceRowtoUpdate} 
                  onSubmit={this.handleInputChange}
                  paymentModeData={paymentModeData} 
                  paymentTypeData={paymentTypeData}
                  initialValues={{invoiceId: invoiceRowtoUpdate.invoiceId}}/>
              </div>}
            </div>
          }
          showButtons={false} // or false, depending on your requirement
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps, mapDispatchToProps
)(InvoiceTable);
