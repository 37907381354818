import {
    GET_ITEM_REQUEST,
    GET_ITEM_REQUEST_SUCCESS, GET_ITEM_REQUEST_FAILURE,
    PRINT_REQUEST,
    PRINT_REQUEST_SUCCESS, PRINT_REQUEST_FAILURE
} from "../actions/itemData.action";


const initialState = {
    itemData: null,
    isLoading: false,
    errorMessage: null,
    printed: false,
    printError: null
}

export const ItemReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_ITEM_REQUEST:
            return {
                ...state,
                isLoading: true,
                itemData: null,
            }
            break;
        case GET_ITEM_REQUEST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                itemData: { ...action.payload }
            }
            break;
        case GET_ITEM_REQUEST_FAILURE:
            return {
                ...state,
                isLoading: false,
                itemData: [],
                errorMessage: action.error.error
            }
            break;
        case PRINT_REQUEST:
            return {
                ...state,
                isLoading: true,
                printed: false,
                printError: null
            }
            break;
        case PRINT_REQUEST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                printed: true,
                printError: null
            }
            break;
        case PRINT_REQUEST_FAILURE:
            return {
                ...state,
                isLoading: false,
                printed: false,
                printError: action.error.error
            }
            break;

        default:
            return {
                ...state
            }
            break;
    }
}