import React, { Component } from 'react';
import { connect } from 'react-redux';
import ResSidebar from '../../SideBar/ResSidebar';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BiLogOut } from 'react-icons/bi';
import { Logout } from '../../Utils/authUser';
import DynamicModal from '../../Form/GenericFields/DynamicModal';
import withRouter from '../../Utils/wthRouter';

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

class ResHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        };
    }

    handleClose = () => this.setState({ showModal: false })
    componentDidUpdate(prevProps) {
        // Check if the route has changed
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.forceUpdate(); // Force a re-render on route change
        }
    }
    render() {
        return (
            <header className='bg-light fixed-top' style={{ boxShadow: "0 5px 5px rgba(0, 0, 0, 0.15)" }} >
                <nav className="navbar container navbar-expand-lg navbar-light d-block">
                    <div className="d-flex justify-content-between align-items-center "  >
                        <a href="/" className="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white text-decoration-none">
                            <h3>{"PlatformOwn"}</h3>
                        </a>
                        <td>

                           {localStorage.getItem('token') && <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip id="edit-tooltip">Logout</Tooltip>}   >
                                <Button variant="danger" size="md" onClick={() => this.setState({ showModal: true })}>
                                    <BiLogOut />
                                </Button>
                            </OverlayTrigger>}
                        </td>
                    </div>
                    <DynamicModal
                        showModal={this.state.showModal}
                        handleClose={this.handleClose}
                        title={"Logout"}
                        size="sm"
                        body={
                            <React.Fragment>
                                <p>Sure you want to Logout?</p>

                            </React.Fragment>
                        }
                        showButtons={true} // or false, depending on your requirement
                        primaryText={"Yes"}
                        cancleText={"No"}
                        handleSave={Logout}
                    />
                </nav>
            </header>
        );
    }
}

export default connect(
    mapStateToProps,
)(withRouter(ResHeader));