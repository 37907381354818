export const uploadFirmDataSchema = (payload) =>{
    const excelDataString = payload.input.map((item) => {
        return `
          {
            firm_name: "${item.firm_name}",
            firm_address: "${item.firm_address}",
            owner_name: "${item.owner_name}",
            pincode: "${item.pincode}",
            gstin_number: "${item.gstin_number}",
            state: "${item.state}",
            state_code: "${item.state_code}",
            bank_name: "${item.bank_name}",
            bank_account_number: "${item.bank_account_number}",
            bank_branch: "${item.bank_branch}",
            bank_ifsc: "${item.bank_ifsc}",
            pan_company: "${item.pan_company}",
            role:"buyer"
          }
        `;
      }).join(',\n');

      const schema = `
      mutation uploadFirmMasterExcel {
        uploadFirmMasterExcel(input: [${excelDataString}]) {
            message
            updatedFirmData{
              id
               firm_name
            firm_address
            owner_name
            pincode
            gstin_number
            state
            state_code
            bank_name
            bank_account_number
            bank_branch
            bank_ifsc
            pan_company
            role
            }
       }
     }`
     return schema
}
