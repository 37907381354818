import { utils, write,  } from "xlsx";


export const exportToExcel = (data,filname) => {
    const filteredData = data.map((item, index) => {
        // Exclude __typename and role
        const { __typename, role, ...rest } = item;
        return { serial: index + 1, ...rest }; // Add serial number and include rest of the data
      });
  // Create a new workbook
  const workbook = utils.book_new();

  // Convert data to worksheet
  const worksheet = utils.json_to_sheet(filteredData);

  // Add the worksheet to the workbook
  utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // Convert the workbook to an array buffer
  const wbArrayBuffer = write(workbook, { type: 'array', bookType: 'xlsx' });

  // Convert the array buffer to a blob
  const wbBlob = new Blob([wbArrayBuffer], { type: 'application/octet-stream' });

  // Create a download link and trigger the download
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(wbBlob);
  link.download = filname+'.xlsx';
  link.click();
};

