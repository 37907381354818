import React, { forwardRef } from 'react';
import PropTypes from "prop-types";

const ComponentToPrint = forwardRef(({ products, discountPercentage }, ref) => {
    const currentDate = new Date(); // Get current date
    const formattedDate = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`;

    const calculateSubtotal = () => {
        let subtotal = 0;
        products?.forEach(product => {
            subtotal += product.price * product.quantity;
        });
        return subtotal;
    };

    const calculateDiscountAmount = () => {
        const subtotal = calculateSubtotal();
        return (subtotal * (discountPercentage / 100)).toFixed(2);
    };

    const calculateTotal = () => {
        const subtotal = calculateSubtotal();
        const discountAmount = calculateDiscountAmount();
        const discountedTotal = subtotal - discountAmount;
        return discountedTotal.toFixed(2);
    };


    return (
        <div ref={ref}>
            <div className="container">
                <div className="logo">
                    <img src="restaurant_logo.png" alt="Restaurant Logo"/>
                </div>
                <div className="receipt-info">
                    <p>Restaurant Name</p>
                    <p>Address Line 1</p>
                    <p>Address Line 2</p>
                    <p>GST Number: GST123456789</p>
                    <p>Date: {formattedDate}</p>
                    <p>Token Number: 12345</p>
                </div>
                <div className="receipt-items">
                    <table>
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Quantity</th>
                                <th>Price</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products?.map((product, index) => (
                                <tr key={index}>
                                    <td>{product.name}</td>
                                    <td>{product.quantity}</td>
                                    <td>${product.price.toFixed(2)}</td>
                                    <td>${(product.price * product.quantity).toFixed(2)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="total">
                    <p>Subtotal: ${calculateSubtotal().toFixed(2)}</p>
                    <p>Discount ({discountPercentage}%): ${calculateDiscountAmount()}</p>
                    <p>Total (after discount): ${calculateTotal()}</p>
                </div>
            </div>
        </div>
    );
});

ComponentToPrint.propTypes = {
    products: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            price: PropTypes.number.isRequired,
            quantity: PropTypes.number.isRequired
        })
    ).isRequired,
    discountPercentage: PropTypes.number.isRequired
};

export default ComponentToPrint;
