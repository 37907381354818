import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { reduxForm } from 'redux-form';
import { validateIsRequired } from '../../../Form/GenericFields/validation';
import GenericFields from '../../../Form/GenericFields/GenericFields';

const EditableForm = ({ handleSubmit, initialValues, editableInt ,onSave , action, errorMessage}) => {
    const [editable, setEditable] = useState(editableInt);
    const [formData, setFormData] = useState(initialValues);

    const toggleEdit = () => {
        setEditable(!editable);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const onSubmit = (e) => {
        //e.preventDefault(); // Prevent default form submission
        onSave(formData,action);
        // You can perform additional actions here, such as dispatching an action to save the form data
    };

    return (

        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-outline mb-4">
                        <GenericFields name="product_name" label="Product Name" onChange={handleInputChange} className="form-control" isDisabled={!editable} validate={[validateIsRequired]} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-outline mb-4">
                        <GenericFields name="hsn_sac" label="HSN/SAC" onChange={handleInputChange} className="form-control" isDisabled={!editable} validate={[validateIsRequired]} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-outline mb-4">
                        <GenericFields name="rate_per_count" label="Rate Per Count" onChange={handleInputChange} className="form-control" isDisabled={!editable} validate={[validateIsRequired]} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-outline mb-4">
                        <GenericFields name="gstin_percentage" label="GSTIN (in %)" onChange={handleInputChange} className="form-control" isDisabled={!editable} validate={[validateIsRequired]} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-outline mb-4">
                        <GenericFields name="district" label="District" onChange={handleInputChange} className="form-control" isDisabled={!editable} validate={[validateIsRequired]} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-outline mb-4">
                        <GenericFields name="season" label="Season" onChange={handleInputChange} className="form-control" isDisabled={!editable} validate={[validateIsRequired]} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-outline mb-4">
                        <GenericFields name="year" label="Year" onChange={handleInputChange} className="form-control" isDisabled={!editable} validate={[validateIsRequired]} />
                    </div>
                </div>
            </div>
            {errorMessage && <p style={{color: "red"}}>{errorMessage}</p>}
            {/* Render other form fields similarly */}

            <Button variant="primary" type="submit" disabled={!editable}>
                Save
            </Button>
            {
                !editableInt && <Button variant="secondary" onClick={toggleEdit}>
                    {editable ? 'Cancel' : 'Edit'}
                </Button>
            }
        </form >

    );
};

// Wrap the form component with reduxForm
const ServiceDataEditDisaplay = reduxForm({
    form: 'editableForm',
})(EditableForm);

export default ServiceDataEditDisaplay;
