export const createFirmDataSchema = (payload)=>`
mutation CreateFirmMaster {
    createFirmMaster(input: {
     firm_name: "${payload.firm_name}",
     firm_address: "${payload.firm_address}",
     owner_name: "${payload.owner_name}",
     pincode: "${payload.pincode}",
     gstin_number: "${payload.gstin_number}",
     state: "${payload.state}",
     state_code: "${payload.state_code}",
     bank_name: "${payload.bank_name}",
     bank_account_number: "${payload.bank_account_number}",
     bank_branch: "${payload.bank_branch}",
     bank_ifsc: "${payload.bank_ifsc}",
     pan_company: "${payload.pan_company}",
     role: "buyer"
   }) {
     id
        firm_name
     firm_address
     owner_name
     pincode
     gstin_number
     state
     state_code
     bank_name
     bank_account_number
     bank_branch
     bank_ifsc
     pan_company
     role
   }
 }
 
`