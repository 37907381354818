export const addPaymentDetailSchema = ({invoiceId,utr_number,payment_type, amount,created_date}) => `
mutation addPaymentDetail {
    addPaymentDetail(input: {
        invoiceId: "${invoiceId}",
        utr_number: "${utr_number}",
        payment_type: "${payment_type}",
        amount: ${amount}
        created_date: "${created_date}"
    }) {
      success
      message
      Invoice{
        adminFirmAddress
      adminFirmName
      adminFirmState
      adminFirmStateCode
      adminGstNumber
      bankAccountNumber
      bankBranchAddress
      bankBranchName
      bankIFSCCode
      buyerFirmAddress
      buyerFirmName
      buyerFirmState
      buyerFirmStateCode
      buyerGstNumber
      invoiceId
      productDetails {
        cgst
        hsn
        igst
        netAmount
        product_name
        quantity
        rate
        sgst
        totalGross
        totalTax
      }
      roundOffTotal
      subTotalGross
      subTotalTax
      subTotalNet
      subTotalNetInWords
      paymentDetails {
        utr_number
        payment_type
        amount
        created_date
      }
      status
      sumPartialAmount
      sumSettledAmount
      }
      
    }
  }
  
`