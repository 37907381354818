import React from 'react';
import {
    FaTh,
    FaUserAlt,
    FaRegChartBar,
    FaCommentAlt,
    FaShoppingBag,
    FaThList,
    FaFileInvoice,
    FaWpforms
} from "react-icons/fa";


export const BASE_API_URLS = {
    LOGIN_API_URL: "http://localhost:3080/auth/login",
    REGISTER_API_URL: "http://localhost:3080/auth/registration",
    CUSTOMER_DETAILS_API_URL: "http://localhost:3080/auth/profile",
    ALL_ADDRESS_API_URL: "http://localhost:3080/auth/alladdress",
    ADD_ADDRESS_API_URL: "http://localhost:3080/auth/editaddress",
    VERIFIED_API_URL: "http://localhost:3080/auth/verify"
}
export const BASE_METHODS ={
    POST: "POST",
    GET:"GET"
}

export const MY_ACCOUNT_HEADING = "Your Account"

export const MY_ACCOUNT_CARDS = [
    {
        IMAGE_ALT: "Your Order",
        IMAGE_SRC: "asset/profile/orders.png",
        CARD_HEADING: "Your Order",
        CARD_VISIBLE: true,
        CARD_DISCRIPTION: "Track, return, or buy things again",
        ROUTE:"/"
    },
    {
        IMAGE_ALT: "Login & Security",
        IMAGE_SRC: "asset/profile/login.png",
        CARD_HEADING: "Login & Security",
        CARD_VISIBLE: true,
        CARD_DISCRIPTION: "Edit login, name, and mobile number",
        ROUTE:"/login&security"
    },
    {
        IMAGE_ALT: "Your Address",
        IMAGE_SRC: "asset/profile/addresses.png",
        CARD_HEADING: "Your Address",
        CARD_VISIBLE: true,
        CARD_DISCRIPTION: "Edit addresses for orders and gifts",
        ROUTE:"/address"
    },
    {
        IMAGE_ALT: "Payment options",
        IMAGE_SRC: "asset/profile/payment.png",
        CARD_HEADING: "Payment options",
        CARD_VISIBLE: true,
        CARD_DISCRIPTION: "Edit or add payment methods",
        ROUTE:"/"
    },
    {
        IMAGE_ALT: "Contact Us",
        IMAGE_SRC: "asset/profile/contact_us.png",
        CARD_HEADING: "Contact Us",
        CARD_VISIBLE: true,
        CARD_DISCRIPTION: "",
        ROUTE:"/"
    }
]

export  const MENU_ITEM_SIDEBAR = [
    {
        path: "/restrobilling",
        name: "Billing",
        icon: <FaFileInvoice />,
        active: true,
        role: "restroSaga"
    },
    {
        path: "/restrotable",
        name: "Tables",
        icon: <FaUserAlt />,
        active: false,
        role: "restroSaga"
    },
    {
        path: "/comment",
        name: "Comment",
        icon: <FaCommentAlt />,
        active: false,
        role: "restroSaga"
    },
    {
        name: "Settings",
        icon: <FaRegChartBar />,
        active: false,
        showModal: true,
        role: "restroSaga"
    },
    { 
        path: "/invoicereport",
        name: "Invoice Report",
        icon: <FaFileInvoice />,
        active: true,
        role: "invoiceSaga"
    },
    {
        path: "/invoiceform",
        name: "Invoice Form",
        icon: <FaWpforms />,
        active: false,
        role: "invoiceSaga"
    },
    {
        path: "/masterdata",
        name: "Master Data",
        icon: <FaWpforms />,
        active: false,
        role: "invoiceSaga"
    },
   
   
];