import { ApolloClient, InMemoryCache, gql, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Logout } from '../modules/Utils/authUser';

const httpLink = createHttpLink({
    uri: 'http://213.210.36.251:40000/graphql' ,// 'http://213.210.36.251:8080/graphql', // Replace with your GraphQL endpoint
});

const httpLinkLogin = createHttpLink({
    uri: 'http://213.210.36.251:40000/login' ,// 'http://213.210.36.251:8080/graphql', // Replace with your GraphQL endpoint
});

const authLink = setContext((_, { headers }) => {
    // Get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // Return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        }
    }
});


const clientGraphql = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});
const clientLogin = new ApolloClient({
    link: authLink.concat(httpLinkLogin),
    cache: new InMemoryCache(),
});
const Query = async (schema, login) => {
    const client = login ? clientLogin : clientGraphql;
    let response
    try {
        response =  await client.query({
            query: gql`${schema}`
        })
    } catch (error) {
        console.log( error)
    }
    
    return response
}

const Mutation = (schema, login) => {
    const client = login ? clientLogin : clientGraphql;
    return client.mutate({
        mutation: gql`${schema}`
    })
}

export { Query, Mutation }
