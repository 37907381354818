import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FirmDataEditDisaplay from './FirmDataEditDisaplay';
import { updateFirmDataRequest } from '../../redux/actions/firmData.action';
import { connect } from 'react-redux';

function mapStateToProps(state) {

    const {  serviceData ,firmData,updateService ,isLoading, errorMessage} = state.masterDataModuleReducer.masterDataReducer;
  
    return { serviceData ,firmData,updateService , isLoading, errorMessage};
  }
  
  const mapDispatchToProps = {
    updateFirmDataRequest
  };

class AdminDataEditDisplay extends Component {
    constructor(props) {
        super(props);
       
    }

    onFirmSave = (formData) =>{
       // this.setState({editableInt: false})
        this.props.updateFirmDataRequest({ id: formData.id, input: formData });
    }

    render() {
        return (
            <div>
              <h3 style={{paddingTop: '20px' , paddingBottom: '10px'}}>Admin Details</h3>
<FirmDataEditDisaplay
              initialValues={this.props.initialValues}
              editableInt={this.props.editableInt}
              onSave={this.onFirmSave}
              action={'update'}
              errorMessage={this.props.errorMessage} /> 
            </div>
        );
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(AdminDataEditDisplay);