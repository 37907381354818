import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { getPrinterDetailsRequest } from '../redux/actions/settings.action';
import DynamicModal from '../../Form/GenericFields/DynamicModal';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

function mapStateToProps(state) {
    const { printerDetails, isLoading, errorMessage } = state.settingModuleReducer.settingsReducer;
    return { printerDetails, isLoading, errorMessage };
}

const mapDispatchToProps = {
    printerDetailsRequest: getPrinterDetailsRequest
}

class RestroSettings extends Component {

    static propTypes = {
        printerDetailsRequest: PropTypes.func,
        printerDetails: PropTypes.oneOfType([
            PropTypes.object, // Object type
            PropTypes.array,  // Array type
        ]),
        isLoading: PropTypes.bool,
        errorMessage: PropTypes.string
    };

    state = {
        selectedPrinter: ""
    }

    componentDidMount = () => {
        this.props.printerDetailsRequest();
    }

    handleSelectPrinter = (e) => {
        const selectedPrinter = e.target.value;
        this.setState({ selectedPrinter });
    }

    handleCheckboxChange = () => {
        this.setState((prevState) => ({
            isChecked: !prevState.isChecked
        }));
    }

    render() {
        const { printerDetails } = this.props;
        const { isChecked, selectedPrinter } = this.state;

        const selectTooltip = (
            <Tooltip id="select-tooltip">
                {selectedPrinter ? `${selectedPrinter}` : "Select your printer from the list."}
            </Tooltip>
        );

        return (
            <div>
                <div className="row">
                    <div className="col-5">Printers</div>
                    <div className="col-1">:</div>
                    <div className="col-6">
                        <OverlayTrigger
                            placement="top"
                            overlay={selectTooltip}
                        >
                            <Form.Select
                                style={{ maxWidth: '200px' }}
                                value={selectedPrinter}
                                onChange={this.handleSelectPrinter}
                            >
                                <option value="">Select Printer</option>
                                {printerDetails?.getPrinters?.map((printer, index) => (
                                    <option key={index} value={printer.name}>
                                        {printer.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </OverlayTrigger>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-5">Billing Type</div>
                    <div className="col-1">:</div>
                    <div className="col-6">
                        <Form.Check
                            type="checkbox"
                            label="Check me"
                            checked={isChecked}
                            onChange={this.handleCheckboxChange}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(RestroSettings);
