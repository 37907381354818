import React, { Component } from 'react';

export const FormWrapper = (props) => {
    const { children, tittle , showNav , parrentTab ,childrens ,navTittle , noPadding} = props
    return <div className={noPadding ? "" :"px-4 py-5 px-md-5  text-lg-start"} >
        <div className="container">
            <div className={"row gx-lg-5 align-items-center justify-content-center "}>
                <div className="col-lg-6 mb-5  mb-lg-0">
                    <h1>{tittle}</h1>
                    <ProfileFormWrapper showNav={showNav} tittle={navTittle} parrentTab={parrentTab} childrens={childrens} />
                    <div className="card" style={{ marginTop: "3%" }}>
                        <div className="card-body py-5 px-md-5">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export const ProfileFormWrapper = (props) => {
    const { childrens, showNav, tittle, parrentTab, alignTab } = props
    return <>
      {showNav && <nav aria-label="breadcrumb">
                        <ol className="breadcrumb link-underline-opacity-0-hover">
                            <li className="breadcrumb-item"><a href="/profile">{parrentTab}</a></li>
                            {childrens.map((item,i) => {
                               return <li key={i} className="breadcrumb-item">{item.path? <a href={item.path}>{item.tittle}</a> : <a>{item.tittle}</a>}</li>
                            })}
                            
                        </ol>
                    </nav>}
                    <h1>{tittle}</h1>
    </>
}