import React from 'react';
import loadingGif from '../../../asset/loader.gif'; // Import your loading GIF

const LoadingRes = () => {
    return (
        <div style={styles.container}>
            <img src={loadingGif} alt="Loading..." style={styles.image} />
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Adjust as needed for full-screen loading
    },
    image: {
        width: '250px', // Adjust the size of the loading GIF
        height: '250px',
    },
};

export default LoadingRes;
