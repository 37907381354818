import React, { useState } from 'react';
import { reduxForm } from 'redux-form';
import { FormWrapper } from '../../../Form/GenericFields/GenericWrappers';
import GenericFields from '../../../Form/GenericFields/GenericFields';
import { Form, Button } from 'react-bootstrap';
import { validateIsOnlyNumber, validateIsRequired } from '../../../Form/GenericFields/validation';
import { Country, State, City } from 'country-state-city';

const mapStateToProps = (state) => {
    const { selectedService } = state.form.addServiceForm.values || ''
    return { selectedService }
}

const AddServiceForm = ({ handleSubmit, saveInvoice, serviceData, handleServiceChange, handleInputChange, currentStep , prevStep, nextStep }) => {
    const stateData = State.getStatesOfCountry("IN").map(state => ({
        value: state.name,
        label: state.name,
        stateCode: state.isoCode
    }))

    const getStateCodeByName = (stateName) => {
        const selectedState = stateData.find(state => state.label === stateName);
        return selectedState ? selectedState.stateCode : '';
    };

    const [selectedStateCode, setSelectedStateCode] = useState(getStateCodeByName(''));

    //const [selectedCityCode, setSelectedCityCode] = useState('');

    const citydata = City.getCitiesOfState('IN', selectedStateCode).map(city => ({
        value: city.name,
        label: city.name,
        cityCode: city.isoCode
    }))

    const seasonData = ['Karib', 'Rabi', 'Zaid'].map(season => ({
        value: season,
        label: season,
    }));

    const currentYear = new Date().getFullYear();

    // Generate an array of years, e.g., from current year to 100 years back
    const years = [];
    for (let year = currentYear; year >= currentYear - 100; year--) {
        years.push(year);
    }

    const yearData = years.map(year => ({
        value: year,
        label: year,
    }));
    const handleStateChange = (selectedState) => {
        setSelectedStateCode(selectedState.stateCode);
    };
    const serviceDataSelect = serviceData.map(service => ({
        value: service.product_name,
        label: service.product_name
    }))

    return (
        <div>
            <FormWrapper showNav={false} navTittle={"Add Service"} parrentTab={""} childrens={[]}>
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-outline mb-4">
                                <GenericFields name="selectedServiceData" label="Select Service" onChange={handleServiceChange} type='select' className="form-control" options={serviceDataSelect} validate={[validateIsRequired]} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-outline mb-4">
                                <GenericFields placeholder="Enter Quantity" name="quantity" label="Quantity" className="form-control" onChange={handleInputChange} validate={[validateIsOnlyNumber, validateIsRequired]} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4">
                            <div className="form-outline">
                                <GenericFields name="State" label="State" onChange={handleStateChange} type='select' options={stateData} className="form-control" validate={[validateIsRequired]} />
                            </div>
                        </div>
                        <div className="col-md-6 mb-4">
                            <div className="form-outline">
                                <GenericFields name="City" label="Town/City" className="form-control" type='select' options={citydata} validate={[validateIsRequired]} />
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="form-outline">
                                <GenericFields name="season" label="Season" type='select' options={seasonData} className="form-control" validate={[validateIsRequired]} />
                            </div>
                        </div>
                        <div className="col-md-12 mb-4">
                            <div className="form-outline">
                                <GenericFields name="year" label="Years" type='select' options={yearData} className="form-control" validate={[validateIsRequired]} />
                            </div>
                        </div>
                    </div>

                    <div className="d-flex gap-2 justify-content-center py-5">
                    <Button variant="primary" onClick={handleSubmit}>
                                Add Service
                            </Button>
                    </div>

                    <div className="d-flex gap-2 justify-content-center py-5">
                        
                        {currentStep !== 1 && (
                            <Button variant="secondary" onClick={prevStep}>
                                {"< Previous"}
                            </Button>
                        )}
                        {currentStep !== 4 ? (
                            <Button variant="primary" onClick={saveInvoice}>
                                {"Save & Next >"}
                            </Button>
                        ) : (
                            <Button variant="success">Submit</Button>
                        )}
                       
                    </div>
                </form>
            </FormWrapper>
        </div>
    );
};

AddServiceForm.propTypes = {

};
export default reduxForm({
    form: 'addServiceForm', // Provide a unique form name
})(AddServiceForm);