export const GET_FIRM_DATA_REQUEST ='GET_FIRM_DATA_REQUEST';
export const GET_FIRM_DATA_REQUEST_SUCCESS ='GET_FIRM_DATA_REQUEST_SUCCESS';
export const GET_FIRM_DATA_REQUEST_FAILURE ='GET_FIRM_DATA_REQUEST_FAILURE';
export const SET_ADMIN_FIRM_DATA_REQUEST= 'SET_ADMIN_FIRM_DATA_REQUEST';
export const SET_BUYER_FIRM_DATA_REQUEST='SET_BUYER_FIRM_DATA_REQUEST';
export const CREATE_FIRM_DATA_REQUEST ='CREATE_FIRM_DATA_REQUEST';
export const CREATE_FIRM_DATA_REQUEST_SUCCESS ='CREATE_FIRM_DATA_REQUEST_SUCCESS';
export const CREATE_FIRM_DATA_REQUEST_FAILURE ='CREATE_FIRM_DATA_REQUEST_FAILURE';
export const UPDATE_FIRM_DATA_REQUEST ='UPDATE_FIRM_DATA_REQUEST';
export const UPDATE_FIRM_DATA_REQUEST_SUCCESS ='UPDATE_FIRM_DATA_REQUEST_SUCCESS';
export const UPDATE_FIRM_DATA_REQUEST_FAILURE ='UPDATE_FIRM_DATA_REQUEST_FAILURE';
export const DELETE_FIRM_DATA_REQUEST ='DELETE_FIRM_DATA_REQUEST';
export const DELETE_FIRM_DATA_REQUEST_SUCCESS ='DELETE_FIRM_DATA_REQUEST_SUCCESS';
export const DELETE_FIRM_DATA_REQUEST_FAILURE ='DELETE_FIRM_DATA_REQUEST_FAILURE';
export const FILE_INSERT_FIRM_DATA_REQUEST ='FILE_INSERT_FIRM_DATA_REQUEST';
export const FILE_INSERT_FIRM_DATA_REQUEST_SUCCESS ='FILE_INSERT_FIRM_DATA_REQUEST_SUCCESS';
export const FILE_INSERT_FIRM_DATA_REQUEST_FAILURE ='FILE_INSERT_FIRM_DATA_REQUEST_FAILURE';

export const getFirmDataRequest = (payload) => ({
    type: GET_FIRM_DATA_REQUEST,payload
}); 

export const getFirmDataRequestSuccess = (payload) => ({
    type: GET_FIRM_DATA_REQUEST_SUCCESS, payload
}); 

export const getFirmDataRequestFailure = (error) => ({
    type: GET_FIRM_DATA_REQUEST_FAILURE , error
}); 

export const setAdminFirmDataRequest = (payload) => ({
    type: SET_ADMIN_FIRM_DATA_REQUEST,payload
});

export const setBuyerFirmDataRequest = (payload) => ({
    type: SET_BUYER_FIRM_DATA_REQUEST,payload
});


export const createFirmDataRequest = (payload) => ({
    type: CREATE_FIRM_DATA_REQUEST,payload
}); 

export const createFirmDataRequestSuccess = (payload) => ({
    type: CREATE_FIRM_DATA_REQUEST_SUCCESS, payload
}); 

export const createFirmDataRequestFailure = (error) => ({
    type: CREATE_FIRM_DATA_REQUEST_FAILURE , error
}); 

export const updateFirmDataRequest = (payload) => ({
    type: UPDATE_FIRM_DATA_REQUEST,payload
}); 

export const updateFirmDataRequestSuccess = (payload) => ({
    type: UPDATE_FIRM_DATA_REQUEST_SUCCESS, payload
}); 

export const updateFirmDataRequestFailure = (error) => ({
    type: UPDATE_FIRM_DATA_REQUEST_FAILURE , error
});
export const deleteFirmDataRequest = (payload) => ({
    type: DELETE_FIRM_DATA_REQUEST,payload
}); 

export const deleteFirmDataRequestSuccess = (payload) => ({
    type: DELETE_FIRM_DATA_REQUEST_SUCCESS, payload
}); 

export const deleteFirmDataRequestFailure = (error) => ({
    type: DELETE_FIRM_DATA_REQUEST_FAILURE , error
});  
export const fileInsertFirmDataRequest = (payload) => ({
    type: FILE_INSERT_FIRM_DATA_REQUEST,payload
}); 

export const fileInsertFirmDataRequestSuccess = (payload) => ({
    type: FILE_INSERT_FIRM_DATA_REQUEST_SUCCESS, payload
}); 

export const fileInsertFirmDataRequestFailure = (error) => ({
    type: FILE_INSERT_FIRM_DATA_REQUEST_FAILURE , error
});  