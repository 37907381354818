import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getVerifiedRequest } from "../redux/actions/verifyToken.action";

function mapStateToProps(state) {
  const { isLoading,successMessage, errorUserMessage } = state.headerModuleReducer.GetVerifyReducer;
  const { isLoading: userdataLoading, userData,  errorUserMessage : userDataErrorMsg } = state.userProfileModuleReducer.GetUserDataReducer;

  return {
    isLoading,successMessage, errorUserMessage ,userdataLoading, userData , userDataErrorMsg
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getVerifiedRequet: getVerifiedRequest,
    },
    dispatch
);
}

class Header extends Component {

  static propTypes = {
    getVerifiedRequet: PropTypes.func,
    isLoading: PropTypes.bool,
    successMessage: PropTypes.string,
    errorUserMessage: PropTypes.string,
    userdataLoading: PropTypes.bool,
    userData: PropTypes.oneOfType([
      PropTypes.object, // Object type
      PropTypes.array,  // Array type
    ]),
    userDataErrorMsg: PropTypes.string
}

componentDidMount= () => {
  //this.props.getVerifiedRequet()
}
  render() {
    const {userData} = this.props;
    const localUser = JSON.parse(localStorage.getItem("user"));
    const init=  localUser?.firstName?.charAt(0).toUpperCase() +   localUser?.lastName?.charAt(0).toUpperCase();
    const listOfNavOption = [{ 
      heading:  'Abbout Us',
      urlPath: 'aboutus'
    },{ 
      heading:  'Base 64 to PDF',
      urlPath: 'base64topdf'
    },{ 
      heading:  'Unix Timestamp',
      urlPath: 'unixconverter'
    },{ 
      heading:  'Home',
      urlPath: ''
    }, 
];
    return <>
      <header>
        <div className="px-3 py-2 int-header-bg-color ">
          <div className="container">
            <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
              <a href="/" className="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white text-decoration-none">
                <img src="asset/logo/utilitys-logo.png" className='logo' alt="Logo" ></img>
              </a>

              <ul className="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">

                {listOfNavOption.map((item, i) => {
                  return <> <li  >

                    <NavLink key={item}  to={'/' + item.urlPath  }  className="nav-link int-header-nav-text int-header">
                      <div style={{ display : 'flex' }}>
                      {item.roundedText ? <p className='int-header-circle-text'>{item.roundedText}</p> : ""}
                      {/* <svg className="bi d-block mx-auto mb-1" width="24" height="24"><use xlink:href="#home"></use></svg> */}
                      <div>{item.heading}</div></div>
                    </NavLink >
                  </li></>
                })}

              </ul>
            </div>
          </div>
        </div>
       
      </header>
    </>
  }
}

export default connect(
  mapStateToProps,mapDispatchToProps
)(Header);