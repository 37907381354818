export const getServiceDataSchema = () => `
  query {
    serviceData {
      id
      product_name
      hsn_sac
      rate_per_count
      gstin_percentage
      district
      season
      year
    }
  }
`;