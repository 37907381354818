import React from 'react';

export const LOADING_INDICATOR_OPTONS= {
    SIZE:{
        SMALL: 'small'
    }
}

const LoadingIndicator = ({size, label, isOverlay, pageLoader}) => {
    return (
        <div>
            <div className={"plt-spinner " + (size? size : '') + " "+ (isOverlay ?'overlay': '') + (pageLoader? ' pageLoader' : '')}>
                <span>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                </span>
            </div>
        </div>
    );
};

export default LoadingIndicator;