import React, { Component } from 'react';

class AboutUs extends React.Component {

    render() {
        const listOfNavOption = ['Home', 'Dashboard', 'About'];
        return <>
        <br></br>
                <br></br>
            {/* <div class="container">
                <h1>About Us Updated</h1>
                <p>Welcome to Interior.in – Your One-Stop Destination for Interior Solutions!</p>

                <p>At Interior.in, we are passionate about creating spaces that reflect your unique style and personality. We understand that your home or workspace is not just a place; it's an extension of who you are and what you stand for. That's why we offer comprehensive interior solutions that cater to all your design and decor needs.</p>

                <p>Our Mission:<br />
                    Our mission is to transform your living and working spaces into a harmonious blend of aesthetics, functionality, and comfort. We strive to provide top-notch interior design services that elevate your surroundings while ensuring maximum utilization of space and resources.</p>

                <p>Our Services:<br />
                    We take pride in being a full-service interior solutions provider, offering a wide range of services to meet your diverse requirements:</p>
                <ul>
                    <li>Residential Interiors: We specialize in crafting breathtaking interiors for your homes, apartments, and villas. From conceptualization to execution, our team of skilled designers and craftsmen will bring your vision to life, creating an abode that truly reflects your lifestyle.</li>
                    <li>Commercial Interiors: For businesses, offices, and retail spaces, we provide smart and innovative designs that boost productivity, impress clients, and leave a lasting impression on visitors.</li>
                    <li>Turnkey Projects: Our turnkey solutions take the hassle out of the entire interior design process. We handle everything from planning and sourcing to execution, so you can sit back and watch your dream space come to life effortlessly.</li>
                    <li>Modular Furniture: We offer a range of modular furniture options that optimize space utilization and provide stylish, functional, and customizable solutions for your needs.</li>
                    <li>Renovation and Remodeling: If you're looking to refresh your existing space, our renovation and remodeling services can breathe new life into your surroundings, giving them a fresh and contemporary look.</li>
                </ul>

                <p>Why Choose Interior.in?<br />
                    - Expert Team: Our team of seasoned designers, architects, and craftsmen bring a wealth of experience and creativity to the table, ensuring excellence in every project we undertake.</p>

                <p>- Customer-Centric Approach: We put you, our valued customer, at the heart of everything we do. Your satisfaction is our ultimate goal, and we strive to exceed your expectations with every project.</p>

                <p>- Quality and Reliability: We take pride in using the finest materials and adhering to the highest standards of quality in our work, ensuring the longevity and durability of our designs.</p>

                <p>- Timely Delivery: We understand the importance of time in the interior design process. Our efficient project management ensures that your projects are completed within the agreed-upon timelines.</p>

                <p>- Affordable Pricing: While we strive for excellence, we also believe in offering competitive and transparent pricing, making luxury interior solutions accessible to a wider audience.</p>

                <p>Let Interior.in be your trusted partner in transforming your spaces into beautiful and functional sanctuaries. Contact us today to embark on an inspiring journey of interior design and discovery. Together, we'll create spaces that speak volumes about you!</p>

            </div> */}

<div class="container">
    <h1>Utility Tools</h1>
    <p>Welcome to Utility Tools – Your One-Stop Destination for Converting Base64 to PDF and Unix Time!</p>

    <p>At Utility Tools, we aim to provide efficient and convenient solutions for your conversion needs. Whether you're dealing with base64 encoded data or need to work with Unix time, our tools are here to streamline the process.</p>

    <p>Our Mission:<br />
        Our mission is to simplify complex conversions and empower users with tools that enhance productivity and efficiency.</p>

    <p>Our Services:<br />
        We specialize in two main areas:</p>
    <ul>
        <li>Base64 to PDF Converter: Our tool allows you to easily convert base64 encoded data into PDF documents. Whether you're working with images, documents, or any other type of data, our converter ensures seamless conversion with just a few clicks.</li>
        <li>Unix Time Converter: Need to work with Unix time? Our Unix time converter tool simplifies the process of converting Unix timestamps to human-readable date and time formats, and vice versa. Say goodbye to manual calculations and let our tool handle the conversions for you.</li>
    </ul>

    <p>Why Choose Utility Tools?<br />
        - User-Friendly Interface: Our tools are designed with simplicity and ease of use in mind, ensuring a smooth user experience for all your conversion needs.</p>

    <p>- Fast and Accurate Results: With our efficient algorithms, you can trust that your conversions will be completed quickly and accurately, saving you time and effort.</p>

    <p>- Accessibility: Our tools are accessible online, allowing you to perform conversions anytime, anywhere, without the need for any additional software or downloads.</p>

    <p>- Secure and Reliable: We prioritize the security and privacy of your data, ensuring that all conversions are conducted securely and confidentially.</p>

    <p>Experience the convenience and efficiency of Utility Tools for all your conversion requirements. Try our tools today and unlock a world of possibilities!</p>

</div>

        </>
    }
}
export default AboutUs;