import React from 'react';
import PropTypes from "prop-types";

const ProgressBarSteps = ({ currentStep, steps }) => {
    const getStepState = i => {
        const step = i + 1;
        if (currentStep === step) {
            return 'active'
        }
        if (step < currentStep) {
            return 'complete'
        }
    }
    return (
        <div>
            <ul className="adc-progress-bar mt-3">
                {steps.map((step, index) =>
                    <>
                        <li key={`progress-step-${index}`}
                            className={`adc-progress-bar__step 
                    ${getStepState(index) ? `adc-progress-bar__step--${getStepState(index)}` : ""}`}   >
                            {step.tittle}
                            <span className='step-line' />
                        </li>
                    </>
                )}
            </ul>

        </div>
    );
};

ProgressBarSteps.propTypes = {
    currentStep: PropTypes.number,
    steps: PropTypes.array
};

export default ProgressBarSteps;