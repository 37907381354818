import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getInvoiceDataRequest } from '../../redux/actions/invoiceData.action';
import InvoiceTable from './InvoiceTable';
import { Button } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { FaPen } from 'react-icons/fa';
import LoadingIndicator from '../../../Form/LoadingIndicator/LoadingIndicator';
import withRouter from '../../../Utils/wthRouter';

function mapStateToProps(state) {
   
    const { invoiceData , isLoading} = state.masterDataModuleReducer.masterDataReducer;

    return { invoiceData , isLoading};

}

const mapDispatchToProps = {
   getInvoiceDataRequest
}

class InvoiceReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isRedirect: false, 
            redirectTo: ''
        }
    }

    componentDidMount= () => {
        this.props.getInvoiceDataRequest();
    }

    createInvoice = () =>{
        this.setState({isRedirect: true, redirectTo: 'invoiceform'})
    }

    componentDidUpdate(prevProps) {
        // Check if the route has changed
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.forceUpdate(); // Force a re-render on route change
        }
        
    }

    render() {
        const { invoiceData } = this.props;
        const {isRedirect, redirectTo}= this.state;
       // this.props.getInvoiceDataRequest();
        return (
            <div className='container'>
                {this.props.isLoading && <LoadingIndicator isOverlay={true} pageLoader={true} />}
                {isRedirect && redirectTo && (<Navigate to={"/" + redirectTo} replace={true} render={true} />)}
                <div className="d-flex justify-content-between align-items-center "  style={{paddingTop: '20px' , paddingBottom: '10px'}}>
    <h3>Invoice Report</h3>
    <Button variant="primary" size="lg" onClick={this.createInvoice}>
        <FaPen /> {" "}Create Invoice
    </Button>
</div>

               {invoiceData && <InvoiceTable invoiceData={invoiceData} />}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,mapDispatchToProps
)(withRouter(InvoiceReport));