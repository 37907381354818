import React, { Component } from 'react';
import { Menu, MenuItem, Sidebar, SubMenu } from 'react-pro-sidebar';
import { Link, Route, Routes } from 'react-router-dom';
import Login from '../Authentication/Login/components/Login';
import AboutUs from '../AboutUs/components/AboutUs';
import InvoiceReport from '../InvoiceCreation/components/InvoiceReport/InvoiceReport';
import Home from '../Home/components/Home';
import Registration from '../Authentication/Registration/components/Registration';
import UserProfile from '../UserProfile/components/UserProfile';
import UserAddress from '../UserProfile/components/UserAddress';
import Base64ToPDFConverter from '../Converters/components/Base64ToPDF';
import UnixConverter from '../Converters/components/UnixConverter';
import RestroBilling from '../RestroBilling/components/RestroBilling';
import RestroTable from '../RestroSaga/components/RestroTable';
import RestroSettings from '../RestroSettings/components/RestroSettings';
import InvoiceForm from '../InvoiceCreation/components/CreateInvoice/InvoiceForm';
import LoadingRes from '../Form/GenericFields/LoadingRes';
import MasterDataDisplay from '../InvoiceCreation/components/MasterDataDisplay/MasterDataDisplay';
import { MENU_ITEM_SIDEBAR } from '../Utils/enum';
import { connect } from 'react-redux';

function mapStateToProps(state) {

    const { loginUserData, isLoading, errorMessage } = state.loginModuleReducer.GetLoginReducer;
    const { values: loginValues } = state.form.loginForm || {};

    return { loginUserData, isLoading, errorMessage, loginValues };
}

const mapDispatchToProps = {

};

class ProSideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            userRole: JSON.parse(localStorage.getItem("user"))?.role
        };
    }

    handleClose = () => {
        this.setState({ showModal: false });
    };

    handleShow = () => {
        this.setState({ showModal: true });
    };
    componentDidUpdate = (prevProps) => {
        const { loginUserData, history } = this.props;
        if (prevProps.loginUserData !== loginUserData) {
            if (loginUserData.token) {
                this.setState({ userRole: loginUserData.role });
            }

        }
    }

    render() {

        const { showModal, userRole } = this.state;
        return (
            <div>
               {this.props.loginUserData && userRole &&  <div style={{ display: "flex", height: "100vh", overflow: "hidden", height: "90vh" }}>
                  <Sidebar className="app">
                        <Menu>
                            {this.props.loginUserData && userRole && MENU_ITEM_SIDEBAR.filter(e => e.role === userRole).map((item, index) =>
                                item.showModal ? (<>
                                    <h4>No Page</h4>
                                </>) : (
                                    <MenuItem component={<Link to={item.path} className="link" />}> {item.name} </MenuItem>
                                ))}
                        </Menu>
                    </Sidebar>
                    <section style={{ width: "100%", overflowY: "scroll" }}>
                        <Routes>
                            {/* <Route exact path="/" element={<UnixConverter />} /> */}
                            {/* <Route path="/" element={<Login />} /> */}
                            <Route path="/aboutus" element={<AboutUs />} />
                            <Route path="/home" element={<Home />} />
                            <Route path="/register" element={<Registration />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/profile" element={<UserProfile />} />
                            <Route path="/address" element={<UserAddress />} />
                            <Route path="/base64topdf" element={<Base64ToPDFConverter />} />
                            <Route path="/unixconverter" element={<UnixConverter />} />
                            <Route path="/restrobilling" element={<RestroBilling />} />
                            <Route path="/restrotable" element={<RestroTable />} />
                            <Route path="/restrosetting" element={<RestroSettings />} />
                            <Route path="/invoiceform" element={<InvoiceForm />} />
                            <Route path="/invoicereport" element={<InvoiceReport />} />
                            <Route path="/loadingpage" element={<LoadingRes />} />
                            <Route path="/masterdata" element={<MasterDataDisplay />} />
                        </Routes>
                    </section>
                    </div>}
                    {/* <Sidebar className="app">
        <Menu>
          <MenuItem
          component={<Link to="/" className="link" />}
           className="menu1">
            <h2>QUICKPAY</h2>
          </MenuItem>
          <MenuItem> Dashboard </MenuItem>
          <MenuItem> Invoices </MenuItem>
          <SubMenu label="Charts">
            <MenuItem> Timeline Chart </MenuItem>
            <MenuItem> Bubble Chart </MenuItem>
          </SubMenu>
          <SubMenu label="Wallets">
            <MenuItem>Current Wallet</MenuItem>
            <MenuItem>Savings Wallet</MenuItem>
          </SubMenu>
          <MenuItem> Transactions </MenuItem>
          <SubMenu label="Settings">
            <MenuItem> Account </MenuItem>
            <MenuItem> Privacy </MenuItem>
            <MenuItem> Notifications </MenuItem>
          </SubMenu>
          <MenuItem> Logout </MenuItem>
        </Menu>
      </Sidebar> */}
                    <section style={{ width: "100%", overflowY: "scroll" }}>
                        <Routes>
                            {/* <Route exact path="/" element={<UnixConverter />} /> */}
                            <Route path="/" element={<Login />} />
                            <Route path="/login" element={<Login />} />
                        </Routes>
                    </section>
                
            </div>
        );
    }
}

export default (connect(
    mapStateToProps, mapDispatchToProps
)(ProSideBar));