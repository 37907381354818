import { useNavigate } from "react-router-dom";

export const getAuthToken = () => {
    const userToken = localStorage.getItem("token")
    return userToken ? { "x-access-token" : userToken ,  'Content-Type': 'application/json'  } : { 'Content-Type': 'application/json' }
}

export const checkTokenExpiration = () => {
    // Check if token is expired
    const token = localStorage.getItem('token');
    const expirationTime = localStorage.getItem('expirationTime');
    if (token && expirationTime) {
        const currentTime = new Date().getTime();
        if (currentTime > Number(expirationTime)) {
            // Token has expired, logout the user
            Logout();
        } 
    }else{
        Logout();
    } 
};

export const Logout = () => {
   // const navigate = useNavigate();

    // Clear token and expiration time from storage
    localStorage.removeItem('token');
    localStorage.removeItem('expirationTime');
    localStorage.removeItem('user');
    // Redirect user to login page
    //navigate('/login');
    window.location.href = '/login';
    // Since the navigate function does not return anything,
    // you can also return null or an empty div
    return null;
};

export const getRedirect = () => {
    const role= JSON.parse(localStorage.getItem("user"))?.role;
            if (role === "invoiceSaga") {
                return "invoicereport"
            }
            if (role === "restroSaga") {
                return "restrobilling"
            }else {
                return "login"
            }
}