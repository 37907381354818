import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class Base64ToPDFConverter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      base64Data: '', // Store base64 data here
      pdfUrl: '',     // Store URL of the converted PDF
    };
  }

  handleInputChange = (event) => {
    const base64Data = event.target.value;
    this.setState({
      base64Data: base64Data,
    });
  };

  convertToPDF = () => {
    const { base64Data } = this.state;
    const byteString = atob(base64Data);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([ab], { type: 'application/pdf' });
    const pdfUrl = URL.createObjectURL(blob);

    this.setState({ pdfUrl });
  };

  render() {
    const { pdfUrl } = this.state;

    return (
      <div>
        <Helmet>
          <title>Base64 to PDF Converter - Utility's</title>
          <meta name="description" content="Convert Base64 to human-readable PDF with ease using Utility's Base64 to PDF Converter." />
          <meta name="keywords" content="Base64 , PDF converter, Converter" />
        </Helmet>
        <main class="container">
          <div class="row justify-content-center" >
            <div className="col col-md-9">
              <h3>Base 64 Text : </h3>
              <textarea
                placeholder="Paste your base64 encoded data here..."
                value={this.state.base64Data}
                onChange={this.handleInputChange}
                style={{ width: '100%', height: '200px' }}
              />
              <button class="btn btn-primary mb-4" type="button" fdprocessedid="xexx1j" onClick={this.convertToPDF}>Convert to PDF</button>
              <h3>PDF Preview : </h3>
              {!pdfUrl && (<p>The result of Base64 decoding will appear here</p>)}
              {pdfUrl && (
                <div>
                  <iframe
                    title="PDF Preview"
                    src={pdfUrl}
                    width="100%"
                    height="500px"
                    style={{ border: '1px solid black' }}
                  ></iframe>
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default Base64ToPDFConverter;
