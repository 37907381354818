import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';

const withRouter = (WrappedComponent) => {
  return (props) => {
    const location = useLocation();

    useEffect(() => {
      // This effect will run on every route change
    }, [location]);

    return <WrappedComponent {...props} location={location} />;
  };
};

export default withRouter;
