import { call, put, takeLatest } from "redux-saga/effects";
import { GET_LOGIN_REQUEST, getLoginRequestFailure, getLoginRequestSuccess } from "../actions/getLoginData.action";
import { BASE_API_URLS, BASE_METHODS } from "../../../../Utils/enum";
import { createRequestOptions } from "../../../../Utils/createOptions";
import { fetchData } from "../../../../Utils/axiosApiCall";
import { sagaHandle } from "../../../../Utils/sagaDataHandling";
import { Mutation, Query } from "../../../../../api/graphql.config";
import { buildUserLoginSchema } from "../schemas/user_login.schema";

export function* getLoginRequestSagaOld({payload}) {
    try {

        const { email, password } = payload;
        const apiUrl = BASE_API_URLS.LOGIN_API_URL;
        const bodyContent = {
            "email": email,
            "password": password
        };
        const methodValue = BASE_METHODS.POST
        const headersValue = { 'Content-Type': 'application/json' }

        const reqOptions = createRequestOptions(apiUrl, methodValue, headersValue, bodyContent)

        const {data} = yield call(fetchData, reqOptions);
        console.log(data)
        
        if (data.success) {
            yield put(getLoginRequestSuccess(data))
        } else {
            yield put(getLoginRequestFailure(data))
        }

    } catch (error) {
        yield put(getLoginRequestFailure(error))
    }
}

export function* getLoginRequestSaga({payload}) {
    try {
        yield call(
            sagaHandle,
            Mutation,
            buildUserLoginSchema(payload),
            getLoginRequestSuccess,
            getLoginRequestFailure,
            true
        )
    } catch (error) {
        yield put(getLoginRequestFailure(error))
    }
}

export default function* userLoginDataSagas() {
    yield takeLatest(GET_LOGIN_REQUEST, getLoginRequestSaga)
}