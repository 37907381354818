export const printerStyle= (body) => {
    if (!body) {
        return null;
    }
    const domparser = new DOMParser();
    const doc = domparser.parseFromString(body, "text/html"); // [object HTMLDocument]
    
    console.log("docs    ",doc);
    const htmlContentsValue = `<!DOCTYPE html>
    <html lang='en'>
    <head>
        <meta charset='UTF-8'>
        <meta name='viewport' content='width=device-width, initial-scale=1.0'>
        <title>Restaurant Receipt</title>
        <style>
        
            body {
                font-family: Arial, sans-serif;
            }
            .container {
                width: 100px;
                //margin: 0 auto;
                padding: 20px;
                border: 1px solid #ccc;
                border-radius: 5px;
            }
            .logo {
                text-align: center;
            }
            .logo img {
                max-width: 100px;
                height: auto;
            }
            .receipt-info {
                margin-top: 20px;
            }
            .receipt-info p {
                margin: 5px 0;
            }
            .receipt-items {
                margin-top: 20px;
            }
            .receipt-items table {
                width: 100%;
                border-collapse: collapse;
            }
            .receipt-items th, .receipt-items td {
                padding: 8px;
                text-align: left;
                border: none;
            }
            .receipt-items th:first-child, .receipt-items td:first-child {
                border-right: 1px solid #ddd;
            }
            .receipt-items th:last-child, .receipt-items td:last-child {
                border-left: 1px solid #ddd;
            }
            .total {
                margin-top: 20px;
                text-align: right;
            }
        </style>
    </head>
    <body>
        ${body}
    </body>
    </html>
    `;
    console.log(htmlContentsValue);
    return htmlContentsValue;
}