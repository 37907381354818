export const updateServiceDataSchema = (payload)=>`
mutation UpdateServiceData {
    updateServiceData(id: ${payload.id},input: {
     product_name: "${payload.input.product_name}"
     hsn_sac: "${payload.input.hsn_sac}"
     rate_per_count: ${payload.input.rate_per_count}
     gstin_percentage: ${payload.input.gstin_percentage}
     district: "${payload.input.district}"
     season:  "${payload.input.season}"
     year: ${payload.input.year}
   })  {
    message
    updatedServiceData { 
      id
     product_name
      hsn_sac
      rate_per_count
      gstin_percentage
      district
      season
      year
    }
}
 }`