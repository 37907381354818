import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { FormWrapper, ProfileFormWrapper } from '../../Form/GenericFields/GenericWrappers';
import { getAddressDataRequest } from '../redux/actions/getAddressData.action';
import { setAddressDataRequest } from '../redux/actions/setAddressData.action';
import { bindActionCreators } from 'redux';
import { MessageBanner } from '../../Form/GenericFields/MessageBanner';
import DisplayAddress from './DisplayAddress';
import EditAddress from './EditAddress';

const mapStateToProps = state => {
    const { isLoading, userData, errorUserMessage } = state.userProfileModuleReducer.GetUserDataReducer;
    const { addressData } = state.userProfileModuleReducer.GetAddressDataReducer;
    const { addressSetMessage,  isLoading : loadingSeting,   errorSetAddressMessage} = state.userProfileModuleReducer.SetAddressDataReducer;
    return { isLoading, userData, errorUserMessage, addressData , addressSetMessage  ,errorSetAddressMessage}
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getAddressDataRequest: getAddressDataRequest,
            setAddressDataRequest: setAddressDataRequest
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(class UserAddress extends Component {

    static propTypes = {
        isLoading: PropTypes.bool,
        userData: PropTypes.oneOfType([
            PropTypes.object, // Object type
            PropTypes.array,  // Array type
        ]),
        errorUserMessage: PropTypes.string,
        getAddressDataRequest: PropTypes.func,
        addressData: PropTypes.oneOfType([
            PropTypes.object, // Object type
            PropTypes.array,  // Array type
        ]),
        setAddressDataRequest: PropTypes.func,
        addressSetMessage: PropTypes.string
    }
    state= {
        isEditAddress: false,
        initialValues: {},
        isNew: true
    }
    componentDidMount = () => {
        const { isLoading, userData, errorUserMessage, getAddressDataRequest } = this.props;
        const { UserEmail } = userData;
        const payload = {
            email: UserEmail
        }
        getAddressDataRequest(payload);
    }

    handleAddressEdit = (payload) => {
        payload['Country'] = {value:payload.Country, label:payload.Country};
        payload['State']= {value:payload.State, label:payload.State};
        payload['City']= {value:payload.City, label:payload.City};
       this.setState({initialValues: {...payload}, isEditAddress: true, isNew:false });
    };

    handelAddressRemove = (id) => {
       this.setState({initialValues: {}, isEditAddress: false, isNew:true })

    };

    handleNewAddress = () => {
        this.setState({initialValues: {}, isEditAddress: true, isNew:true })
    }

    handelAddressDefault = (payload) => {
        const { setAddressDataRequest } = this.props;
        payload['DefaultAddress'] = true;
        setAddressDataRequest(payload)
    };
 onSubmit = formData => {
    const { setAddressDataRequest } = this.props;
    formData['Country'] = formData.Country.value;
    formData['State'] = formData.State.value;
    formData['City'] = formData.City.value;
    formData['DefaultAddress'] = false;
    setAddressDataRequest(formData)
    this.setState({initialValues: {}, isEditAddress: false, isNew:false })
    };

    render() {
        const initialValues = {
            AddressID: 1,
            UserID: 9,
            AddressName: 'Atharva',
            HouseNumber: 'New41',
            StreetName: 'Ramganj',
            LandMark: 'near Park1',
            MobileNumber: '9876543211',
            City: 'Khandwa',
            State: 'New State',
            Country: {value:'India', label:'India'},
            Pincode: '450001',
            DefaultAddress: 1
        };
        return <>
            <div>{!this.state.isEditAddress ?  <DisplayAddress {...this.props}
                    handelEdit={this.handleAddressEdit}
                    handelRemove={this.handelAddressRemove}
                    handelDefault={this.handelAddressDefault}
                    handleAddNew = {this.handleNewAddress}
                    /> : <EditAddress initialValues={this.state.initialValues} onSubmit={this.onSubmit}  />}
            </div>
        </>
    }
})
