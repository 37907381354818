export const createServiceDataSchema = (payload)=>`
mutation CreateServiceData {
    createServiceData(input: {
     product_name: "${payload.product_name}"
     hsn_sac: "${payload.hsn_sac}"
     rate_per_count: ${payload.rate_per_count}
     gstin_percentage: ${payload.gstin_percentage}
     district: "${payload.district}"
     season:  "${payload.season}"
     year: ${payload.year}
   }) {
     id
      product_name
       hsn_sac
       rate_per_count
       gstin_percentage
       district
       season
       year
   }
 }`