import React, { Component } from 'react';

import reactDom, { render } from "react-dom";
import { Provider, connect } from 'react-redux';
import Wizard from '../../../Form/GenericFields/Wizard';
import { getFirmDataRequest, setAdminFirmDataRequest, setBuyerFirmDataRequest } from '../../redux/actions/firmData.action';
import { getServiceDataRequest } from '../../redux/actions/serviceData.action';
import InvoicePreview from './InvoicePreview';
import DynamicModal from '../../../Form/GenericFields/DynamicModal';
import { Button, Form, ProgressBar } from 'react-bootstrap';
import ProgressBarSteps from '../../../Form/GenericFields/ProgressBar';
import FirmMasterDisplay from './FirmMasterDisplay';
import ServiceDataDisplay from './ServiceDataDisplay';
import { FormWrapper } from '../../../Form/GenericFields/GenericWrappers';
import { getCurrentFinancialYear, getPrintParamInvoiceBase64, numberToWords, toTitleCase } from '../../../Utils/invoiceUtils';
import { getInvoiceDataRequest, getPDFBase64Request } from '../../redux/actions/invoiceData.action';
// import PdfViewer from './PDFViewerComponent';
import IframePreview from './IframePreview';
import LoadingIndicator from '../../../Form/LoadingIndicator/LoadingIndicator';
import * as ReactDOMServer from 'react-dom/server';
import store from '../../../../redux/store';
import ReactToPrint from 'react-to-print';
import withRouter from '../../../Utils/wthRouter';
// import { Viewer } from '@react-pdf-viewer/core';

function mapStateToProps(state) {

    const { firmData, isLoading, errorMessage, serviceData, addedAdminFirmData, addedBuyerFirmData, invoiceData, base64InvoicePDF } = state.masterDataModuleReducer.masterDataReducer;

    return { firmData, isLoading, errorMessage, serviceData, addedAdminFirmData, addedBuyerFirmData, invoiceData, base64InvoicePDF };
}

const mapDispatchToProps = {
    getFirmDataRequest,
    getServiceDataRequest,
    setAdminFirmDataRequest,
    setBuyerFirmDataRequest,
    getInvoiceDataRequest,
    getPDFBase64Request
};

class InvoiceForm extends Component {
    constructor(props) {
        super(props);
        this.componentRef = React.createRef();
        this.state = {
            showModal: false,
            invoiceData: {
                invoiceId: null,
                adminFirmName: '',
                adminFirmAddress: '',
                adminFirmState: '',
                adminFirmStateCode: '',
                adminGstNumber: '',
                buyerFirmName: '',
                buyerFirmAddress: '',
                buyerFirmState: '',
                buyerFirmStateCode: '',
                buyerGstNumber: '',
                productDetails: [],
                subTotalGross: 0,
                subTotalTax: 0,
                subTotalNet: 0,
                roundOffTotal: 0,
                subTotalNetInWords: '',
                bankBranchName: '',
                bankAccountNumber: '',
                bankBranchAddress: '',
                bankIFSCCode: '',
                logo: '',

            },
            currentStep: 1,
            steps: [
                { tittle: "Admin" },
                { tittle: "Buyers" },
                { tittle: "Products" },
                { tittle: "FinalPreview" },
            ],
            selectedOption: '',
            invoicPrefix: '',
            pdfUrl: '',
            errorMessage: '',
            htmlContents: "",
            imageBase64: '',
            selectedOption: '',
            base64Content: ""
        };
    }

    getLastInvoiceId = () => {
        const { invoiceData } = this.props;
        if (!Array.isArray(invoiceData) || invoiceData.length === 0) {
            return null;
        }
        const newInvoiceId = parseInt(invoiceData[invoiceData.length - 1].invoiceId.split('/')[2]) + 1;
        return newInvoiceId;
    };

    componentDidMount = () => {
        this.props.getFirmDataRequest();
        this.props.getServiceDataRequest();
        this.props.getInvoiceDataRequest();
        // Load your logo file here (replace 'logo.png' with your file path)
        // import('asset/logo/logo.jpg')
        //   .then(image => {
        // Convert image file to Base64
        this.getBase64FromUrl('asset/logo/logo.jpg')
            .then(base64 => {
                const updatedInvoiceData = { ...this.state.invoiceData };
                updatedInvoiceData.logo = base64;

                // Update the state with the modified invoiceData
                this.setState({ invoiceData: updatedInvoiceData });
            })
            .catch(error => console.error(error));

    }

    getBase64FromUrl = async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    };
    componentDidUpdate = (prevProps, prevState) => {
        const { firmData, invoiceData, base64InvoicePDF } = this.props;
        if (prevProps.firmData !== firmData && firmData && firmData.length > 0) {
            const { firm_name, firm_address, state, state_code, gstin_number, bank_name, bank_account_number, bank_branch, bank_ifsc } = firmData?.filter(e => e.role === 'admin')[0];
            // Create a copy of the current invoiceData state
            const updatedInvoiceData = { ...this.state.invoiceData };

            // Update only the admin details in the copied invoiceData object
            updatedInvoiceData.adminFirmName = firm_name;
            updatedInvoiceData.adminFirmAddress = firm_address;
            updatedInvoiceData.adminFirmState = state;
            updatedInvoiceData.adminFirmStateCode = state_code;
            updatedInvoiceData.adminGstNumber = gstin_number;
            updatedInvoiceData.bankBranchName = bank_name;
            updatedInvoiceData.bankAccountNumber = bank_account_number;
            updatedInvoiceData.bankBranchAddress = bank_branch;
            updatedInvoiceData.bankIFSCCode = bank_ifsc;

            // Update the state with the modified invoiceData
            this.setState({ invoiceData: updatedInvoiceData });
            this.props.setAdminFirmDataRequest(this.props.firmData.filter(e => e.role === 'admin')[0])

        }
        if (prevProps.invoiceData !== invoiceData && this.getLastInvoiceId()) {
            const updatedInvoiceData = { ...this.state.invoiceData };
            // console.log(!updatedInvoiceData.invoiceId);
            // if (!updatedInvoiceData.invoiceId) {
                // Update only the admin details in the copied invoiceData object
                updatedInvoiceData.invoiceId = "AB/" + getCurrentFinancialYear() + "/" + this.getLastInvoiceId()

                // Update the state with the modified invoiceData
                this.setState({ invoiceData: updatedInvoiceData });
            // }
        }
        if (prevProps.base64InvoicePDF !== base64InvoicePDF) {
            this.convertToPDF(base64InvoicePDF);
            if (this.state.currentStep !== 4) {
                this.setState({ showModal: true })
            }

            // this.nextStep();
        }
    }

    handleClose = () => {
        this.setState({ showModal: false });
    };

    handleShow = () => {
        this.setState({ showModal: true });
    };

    // Function to go to the next step
    nextStep = () => {
        const { currentStep } = this.state;
        if (currentStep < 4) {
            this.setState({
                currentStep: currentStep + 1
            });
        }
    };

    // Function to go to the previous step
    prevStep = () => {
        const { currentStep } = this.state;
        if (currentStep > 1) {
            this.setState({
                currentStep: currentStep - 1
            });
        }
    };

    handleSelectChange = (event) => {
        this.props.setBuyerFirmDataRequest(this.props.firmData.filter(e => e.firm_name === event.target.value)[0])
        const { firm_name, firm_address, state, state_code, gstin_number } = this.props.firmData.filter(e => e.firm_name === event.target.value)[0];
        // Create a copy of the current invoiceData state
        const updatedInvoiceData = { ...this.state.invoiceData };

        // Update only the admin details in the copied invoiceData object
        updatedInvoiceData.buyerFirmName = firm_name;
        updatedInvoiceData.buyerFirmAddress = firm_address;
        updatedInvoiceData.buyerFirmState = state;
        updatedInvoiceData.buyerFirmStateCode = state_code;
        updatedInvoiceData.buyerGstNumber = gstin_number;

        // Update the state with the modified invoiceData
        this.setState({ invoiceData: updatedInvoiceData, selectedOption: event.target.value });
    };

    calculateInvoiceSummary = (productDetail) => {
        let subTotalGross = 0;
        let subTotalTax = 0;
        let subTotalNet = 0;

        // Calculate subtotal, total tax, and total net
        productDetail.forEach((product) => {
            subTotalGross += product.totalGross;
            subTotalTax += product.totalTax;
            subTotalNet += product.netAmount;
        });

        // Calculate round off total (Assuming rounding to 2 decimal places)
        let roundOffTotal = Math.round(subTotalNet); // Round to nearest whole number
        let subTotalNetInWords = toTitleCase(numberToWords(roundOffTotal)); // Convert total net amount to words

        return {
            subTotalGross,
            subTotalTax,
            subTotalNet,
            roundOffTotal,
            subTotalNetInWords,
        };
    };

    addServiceToState = (serviceDataSate) => {
        const updatedInvoiceData = { ...this.state.invoiceData };
        const { subTotalGross, subTotalTax, subTotalNet, roundOffTotal, subTotalNetInWords } = this.calculateInvoiceSummary(serviceDataSate);
        // Update only the admin details in the copied invoiceData object
        updatedInvoiceData.productDetails = [...serviceDataSate];
        updatedInvoiceData.subTotalGross = subTotalGross;
        updatedInvoiceData.subTotalTax = subTotalTax;
        updatedInvoiceData.subTotalNet = subTotalNet;
        updatedInvoiceData.roundOffTotal = roundOffTotal;
        updatedInvoiceData.subTotalNetInWords = subTotalNetInWords;

        // Update the state with the modified invoiceData
        this.setState({ invoiceData: updatedInvoiceData });
    }
    handleInvoiceInputChange = (e) => {

        this.setState({ invoicPrefix: e.target.value });
    }

    createInvoiceID = () => {
        const updatedInvoiceData = { ...this.state.invoiceData };

        // Update only the admin details in the copied invoiceData object
        updatedInvoiceData.invoiceId = "AB/" + getCurrentFinancialYear() + "/" + this.state.invoicPrefix

        // Update the state with the modified invoiceData
        this.setState({ invoiceData: updatedInvoiceData });
    }

    onDeleteProduct = (productName) => {
        const productDetails = this.state.invoiceData.productDetails;

        // Find index of products to delete
        const indexesToDelete = [];
        productDetails.forEach((product, index) => {
            if (product.product_name.toLowerCase() === productName.toLowerCase()) {
                indexesToDelete.push(index);
            }
        });

        // Remove products from the array by index (in reverse order to avoid index shift issues)
        for (let i = indexesToDelete.length - 1; i >= 0; i--) {
            productDetails.splice(indexesToDelete[i], 1);
        }

        // Optionally return the modified invoiceData (though it's modified in place)
        return "Delete Successfully";
    }

    convertToPDF = (base64Data) => {
        // const { base64Data } = this.state;
        try {
            const base64Content = base64Data.replace(/^data:application\/pdf;base64,/, '');

            const byteString = atob(base64Content);
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);

            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            const blob = new Blob([ab], { type: 'application/pdf' });
            const pdfUrl = URL.createObjectURL(blob);

            this.setState({ pdfUrl, errorMessage: '' });
        } catch (error) {
            console.error('Base64 decoding error:', error);
            this.setState({ pdfUrl: '', errorMessage: 'Error converting to PDF. Please provide valid base64-encoded data.' });
        }

    };
    generateHtmlContent() {
        if (this.componentRef.current) {
            console.log(this.componentRef.current)
            const htmlContent = this.componentRef.current.innerHTML;
            const base64Content = btoa(htmlContent); // Encode HTML content to base64
            return base64Content;
            //this.setState({ base64Content });
        }
        console.log("out")
        return '';
    }
    handlePrintInvoice = () => {
        if (this.state.currentStep !== 4) {
            this.setState({ showModal: true })
        }
    }

    handleSelectStateChange = (e) => {
        this.setState({ selectedState: e.target.value });
    }

    render() {
        const { showModal, currentStep, steps, selectedOption, invoiceData, invoicPrefix, htmlContents, pdfUrl, imageBase64, selectedState } = this.state;

        return (
            <div>
                {this.props.isLoading && <LoadingIndicator isOverlay={true} pageLoader={true} />}
                {invoiceData.invoiceId ?
                    <div className="container text-center">
                        <h2 className="mb-4">Creating Invoice : {invoiceData.invoiceId}</h2>

                        <div className="container mt-5">
                            <ProgressBarSteps currentStep={currentStep} steps={steps} />
                        </div>
                        {/* Form Content for each step */}
                        <div className="container mt-5">
                            {/* <Button className='btn btn-primary rounded-pill px-3' onClick={this.handleShow}>
                               Show Preview
                            </Button>  */}
                            {"  "}
                            {currentStep !== 4 && <Button className='btn btn-secondary rounded-pill px-3' onClick={this.handlePrintInvoice}>
                                Show Invoice
                            </Button>}
                        </div>
                        {currentStep === 1 && (<>
                            <FormWrapper showNav={false} navTittle={"Admin Details"} parrentTab={""} childrens={[]}>

                                <Form.Group controlId="step1">
                                    {this.props.addedAdminFirmData && <FirmMasterDisplay initialValues={this.props.addedAdminFirmData} currentStep={currentStep} prevStep={this.prevStep} nextStep={this.nextStep} />}
                                </Form.Group>
                            </FormWrapper>
                        </>
                        )}

                        {currentStep === 2 && (
                            <FormWrapper showNav={false} navTittle={"Select Buyer"} parrentTab={""} childrens={[]}>
                                <Form.Group controlId="step2">
                                    <Form.Control as="select" value={selectedState} onChange={this.handleSelectStateChange} required>
                                        <option value="">Select a buyer...</option>
                                        {[...new Set(this.props.firmData.filter(e => e.role === 'buyer').map(item => item.state))].map((state, index) => (
                                            <option key={index} value={state}>{state}</option>
                                        ))}
                                    </Form.Control>
                                    <br></br>
                                    <br></br>
                                    {selectedState && <Form.Control as="select" value={selectedOption} onChange={this.handleSelectChange} required>
                                        <option value="">Select a buyer...</option>
                                        {this.props.firmData.filter(e => e.role === 'buyer' && e.state === selectedState).map((item, index) => (<>
                                            <option value={item.firm_name}>{item.firm_name}</option>
                                        </>))}

                                    </Form.Control>}

                                    <br></br>

                                    <br></br>
                                    {this.props.addedBuyerFirmData && selectedOption && <FirmMasterDisplay initialValues={this.props.addedBuyerFirmData} currentStep={currentStep} prevStep={this.prevStep} nextStep={this.nextStep} />}

                                </Form.Group>
                            </FormWrapper>
                        )}

                        {currentStep === 3 && (
                            <Form.Group controlId="step3">
                                {invoiceData && <ServiceDataDisplay serviceData={this.props.serviceData} addServiceToState={this.addServiceToState} onDeleteProduct={this.onDeleteProduct} invoiceData={invoiceData} currentStep={currentStep} prevStep={this.prevStep} nextStep={this.nextStep} createPDF={this.handlePrintInvoice} />}
                            </Form.Group>
                        )}

                        {currentStep === 4 && (
                            // <FormWrapper showNav={false} navTittle={" Invoice Saved SuccessFully"} parrentTab={""} childrens={[]}>
                            <Form.Group controlId="step4" className='justified-center'>
                                <br></br>

                                <div className="container">
                                    <h3>{"Invoice Saved SuccessFully"}</h3>
                                    <div >
                                        {invoiceData && <div>
                                            <InvoicePreview invoiceData={invoiceData} modal={false} />
                                        </div>}
                                    </div>

                                    {/* {htmlContents && <PdfViewer htmlContent={htmlContents} />} */}
                                </div>
                                {/* <Form.Text className="text-muted">Please review your information before submitting.</Form.Text> */}
                            </Form.Group>
                            // </FormWrapper>

                        )}

                        {/* Navigation Buttons */}
                        {/* <div className="mt-4">
                        {currentStep !== 1 && (
                            <Button variant="secondary" onClick={this.prevStep}>
                                Previous
                            </Button>
                        )}
                        {currentStep !== 4 ? (
                            <Button variant="primary" onClick={this.nextStep}>
                                Next
                            </Button>
                        ) : (
                            <Button variant="success">Submit</Button>
                        )}
                    </div> */}
                    </div> : <>
                        <FormWrapper showNav={false} navTittle={"Select Buyer"} parrentTab={""} childrens={[]}>
                            <span className="me-2">{"AB/" + getCurrentFinancialYear() + "/"}</span>
                            <Form.Control
                                type="number"
                                value={invoicPrefix}
                                onChange={this.handleInvoiceInputChange}

                                placeholder="Enter value"
                            />
                            <Button variant="primary" className="ms-2" onClick={this.createInvoiceID}>
                                Submit
                            </Button>
                        </FormWrapper>
                    </>}

                {/* <Wizard {...this.props} /> */}
                <DynamicModal
                    showModal={showModal}
                    handleClose={this.handleClose}
                    title="Dynamic Modal"
                    size='lg'
                    body={
                        <div >
                            {invoiceData && <div>
                                <InvoicePreview invoiceData={invoiceData} modal={true} />
                            </div>}
                        </div>
                    }
                    showPrintButton={true}
                    showButtons={false} // or false, depending on your requirement
                />
                <div ref={this.componentRef} id="invoice-to-print" style={{ display: 'none' }}>{invoiceData && <InvoicePreview invoiceData={invoiceData} />}</div>

            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(withRouter(InvoiceForm));