import axios from 'axios';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class UnixConverter extends Component {
    constructor(props) {
        super(props);
        const now = new Date();
        this.state = {
            timestamp: Math.floor(now.getTime() / 1000),
            format: '',
            gmt: '',
            local: '',
            relative: '',
            timestampCurrent: Math.floor(now.getTime() / 1000),
            year: now.getFullYear(),
            month: String(now.getMonth() + 1).padStart(2, '0'),
            day: String(now.getDate()).padStart(2, '0'),
            hours: String(now.getHours()).padStart(2, '0'),
            minutes: String(now.getMinutes()).padStart(2, '0'),
            seconds: String(now.getSeconds()).padStart(2, '0'),
            timestampEntry: '',
            gmtEntry: '',
            localEntry: '',
            relativeEntry: '',
        };
    }

    componentDidMount() {
        // Set up an interval to update the timestamp every second
        this.intervalId = setInterval(() => {
            this.updateTimestamp();
        }, 1000);
    }

    componentWillUnmount() {
        // Clear the interval to prevent memory leaks when the component is unmounted
        clearInterval(this.intervalId);
    }

    updateTimestamp = () => {
        // Update the timestamp in the state
        this.setState({
            timestampCurrent: Math.floor(Date.now() / 1000),
        });

        // Call the conversion function if needed

    };

    calculateUnixTimestamp = () => {
        // Calculate the Unix timestamp based on the input field values
        const { year, month, day, hours, minutes, seconds } = this.state;
        const yearEnter = year;
        const monthEnter = month.length < 2 ? month.padStart(2, "0") : month;
        const dayEnter = day.length < 2 ? day.padStart(2, "0") : day;
        const hoursEnter = hours.length < 2 ? hours.padStart(2, "0") : hours;
        const minutesEnter = minutes.length < 2 ? minutes.padStart(2, "0") : minutes;
        const secondsEnter = seconds.length < 2 ? seconds.padStart(2, "0") : seconds;
        const inputDate = new Date(`${year}-${monthEnter}-${dayEnter}T${hoursEnter}:${minutesEnter}:${secondsEnter}`);
        return Math.floor(inputDate.getTime() / 1000);
    };
    isMillisecondsTimestamp = (timestamp) => {
        // Check if the timestamp is a number
        if (typeof timestamp !== 'number') {
            return false;
        }

        // Convert the timestamp to a string
        const timestampString = timestamp.toString();

        // Check if the length is 13 (milliseconds) or 10 (seconds)
        return timestampString.length === 13;
    }
    convertTime = (type) => {
        // Assuming you have the conversion logic here
        // Update the state with the converted values

        if (type === 'timestamp') {
            // Example conversion logic, you need to implement your own
            const timestamp = Number(this.state.timestamp);

            const isMilliseconds = this.isMillisecondsTimestamp(timestamp);
            let date;
            if (isMilliseconds) {
                date = new Date(timestamp);
            } else {
                date = new Date(timestamp * 1000); // Convert to milliseconds
            }


            const format = 'YYYY-MM-DD HH:mm:ss'; // Example format
            const gmt = date.toGMTString();
            const local = date.toLocaleString();
            const relative = this.calculateRelativeTime(date);

            this.setState({
                format,
                gmt,
                local,
                relative,
            });
        } else if (type === 'entry') {
            const timestamp = this.calculateUnixTimestamp();
            const date = new Date(timestamp * 1000);

            const timestampEntry = timestamp * 1000;
            const gmtEntry = date.toGMTString();
            const localEntry = date.toLocaleString();
            const relativeEntry = this.calculateRelativeTime(date);

            this.setState({
                timestampEntry,
                gmtEntry,
                localEntry,
                relativeEntry,
            });
        }
    };

    calculateRelativeTime = (date) => {
        // Example relative time calculation, you need to implement your own
        const now = new Date();
        const diffInSeconds = Math.floor((now - date) / 1000);

        if (diffInSeconds < 0) {
            return 'Just now';
        } else if (diffInSeconds < 60) {
            return 'Just now';
        } else if (diffInSeconds < 3600) {
            const minutes = Math.floor(diffInSeconds / 60);
            return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
        } else {
            const hours = Math.floor(diffInSeconds / 3600);
            return `${hours} hour${hours === 1 ? '' : 's'} ago`;
        }
    };

    handleInputChange = (e) => {
        // Update the state when the input fields change
        const { name, value } = e.target;
        //this.setState({ [name]: value });
        if (name === 'year' && value <= 3000) {
            // Update the state only if the conditions are met
            this.setState({ [name]: value });
        }
        if (name === 'month' && value <= 12) {
            // Update the state only if the conditions are met
            this.setState({ [name]: value });
        }
        if (name === 'day' && value <= 31) {
            // Update the state only if the conditions are met
            this.setState({ [name]: value });
        }
        if (name === 'hours' && value <= 24) {
            // Update the state only if the conditions are met
            this.setState({ [name]: value });
        }
        if (name === 'minutes' && value <= 60) {
            // Update the state only if the conditions are met
            this.setState({ [name]: value });
        }
        if (name === 'seconds' && value <= 60) {
            // Update the state only if the conditions are met
            this.setState({ [name]: value });
        }

    };

    render() {
        return (

            <div>
                <Helmet>
                    <title>{"Utility's - Epoch & Unix Timestamp Conversion Tool"}s</title>
                    <meta name="description" content="Utility's provides tools for converting Epoch and Unix timestamps to human-readable dates and vice versa." />

                    <meta name="keywords" content="Utility's, Epoch, Unix Timestamp, Conversion Tools" />

                </Helmet>
                <main className="container">
                    <div className="row justify-content-center">
                        <div className="col col-md-9">
                            <div className="text-center py-3">
                                <h1 className="h2">Epoch &amp; Unix Timestamp Conversion Tools</h1>
                                <p className="lead mb-1">The Current Epoch Unix Timestamp</p>
                            </div>
                            <div className="text-center mb-4">
                                <div className="epoch h1">{this.state.timestampCurrent}</div>
                            </div>
                            <div id="message" style={{ display: 'none' }}></div>
                            <form>
                                <h2 className="h3">Convert epoch to human-readable date and vice versa</h2>
                                <div className="form-group">
                                    <label htmlFor="timestamp"><strong>Enter a Timestamp</strong></label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        min="0"
                                        name="timestamp"
                                        id="timestamp"
                                        placeholder="1705558667"
                                        value={this.state.timestamp}
                                        onChange={(e) => this.setState({ timestamp: e.target.value })}
                                        aria-describedby="inputHelp"
                                    />
                                    <small id="inputHelp" className="form-text text-muted">
                                        Supports Unix timestamps in seconds, milliseconds, microseconds, and nanoseconds.
                                    </small>
                                </div>
                                <br></br>
                                <button
                                    className="btn btn-primary mb-4"
                                    type="button"
                                    onClick={() => this.convertTime('timestamp')}
                                >
                                    Convert Timestamp to Human date &rarr;
                                </button>
                                {this.state.format && <table id="timestamp-results" className="table table-bordered table-striped table-hover">
                                    <tbody>
                                        <tr><td width="200">Format</td><td className="format">{this.state.format}</td></tr>
                                        <tr><td width="200">GMT</td><td className="gmt">{this.state.gmt}</td></tr>
                                        <tr><td>Your Time Zone</td><td className="local">{this.state.local}</td></tr>
                                        <tr><td>Relative</td><td className="relative">{this.state.relative}</td></tr>
                                    </tbody>
                                </table>}
                                <h2>Enter a Date &amp; Time</h2>
                                <div id="entry-inputs" className="form-row" style={{ display: "flex", flexWrap: "wrap", marginRight: "-5px", marginLeft: "-5px" }}>
                                    <div className="form-group col-sm-6 col-md-2">
                                        <label htmlFor="y">Year</label>
                                        <input
                                            name="year"
                                            type="number"
                                            id="y"
                                            className="form-control"
                                            step="1"
                                            monKeyDown={(e) => {
                                                if (e.target.value.length >= 4 && e.key.length === 1) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            placeholder="YYYY"
                                            value={this.state.year}
                                            onChange={this.handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group col-sm-6 col-md-2">
                                        <label htmlFor="m">Month</label>
                                        <input
                                            name="month"
                                            type="number"
                                            id="m"
                                            className="form-control"
                                            min="1"
                                            max="12"
                                            step="1"
                                            onKeyDown={(e) => {
                                                if (e.target.value.length >= 2 && e.key.length === 1) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            placeholder="MM"
                                            value={this.state.month}
                                            onChange={this.handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group col-sm-6 col-md-2">
                                        <label htmlFor="d">Day</label>
                                        <input
                                            name="day"
                                            type="number"
                                            id="d"
                                            className="form-control"
                                            min="1"
                                            max="31"
                                            step="1"
                                            onKeyDown={(e) => {
                                                if (e.target.value.length >= 2 && e.key.length === 1) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            placeholder="DD"
                                            value={this.state.day}
                                            onChange={this.handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group col-sm-6 col-md-2">
                                        <label htmlFor="hours">Hour (24h)</label>
                                        <input
                                            name="hours"
                                            type="number"
                                            id="hours"
                                            className="form-control"
                                            min="0"
                                            max="23"
                                            step="1"
                                            onKeyDown={(e) => {
                                                if (e.target.value.length >= 2 && e.key.length === 1) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            placeholder="HH"
                                            value={this.state.hours}
                                            onChange={this.handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group col-sm-6 col-md-2">
                                        <label htmlFor="min">Minutes</label>
                                        <input
                                            name="minutes"
                                            type="number"
                                            id="min"
                                            className="form-control"
                                            step="1"
                                            min="0"
                                            max="59"
                                            onKeyDown={(e) => {
                                                if (e.target.value.length >= 2 && e.key.length === 1) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            placeholder="MM"
                                            value={this.state.minutes}
                                            onChange={this.handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group col-sm-6 col-md-2">
                                        <label htmlFor="sec">Seconds</label>
                                        <input
                                            name="seconds"
                                            type="number"
                                            id="sec"
                                            className="form-control"
                                            step="1"
                                            min="0"
                                            max="59"
                                            onKeyDown={(e) => {
                                                if (e.target.value.length >= 2 && e.key.length === 1) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            placeholder="SS"
                                            value={this.state.seconds}
                                            onChange={this.handleInputChange}
                                        />
                                    </div>
                                </div>
                                <br></br>
                                <button
                                    className="btn btn-primary mb-4"
                                    type="button"
                                    onClick={() => this.convertTime('entry')}
                                >
                                    Convert Human date to Timestamp &rarr;
                                </button>
                                {this.state.timestampEntry && <table id="timestamp-results" className="table table-bordered table-striped table-hover">
                                    <tbody>
                                        <tr><td width="200">Unix Timestamp</td><td className="format">{this.state.timestampEntry}</td></tr>
                                        <tr><td width="200">GMT</td><td className="gmt">{this.state.gmtEntry}</td></tr>
                                        <tr><td>Your Time Zone</td><td className="local">{this.state.localEntry}</td></tr>
                                        <tr><td>Relative</td><td className="relative">{this.state.relativeEntry}</td></tr>
                                    </tbody>
                                </table>}
                            </form>
                            <p>
                                Welcome to our Epoch Converter tool! If you're working with epoch time, timestamps, or Unix time,
                                you've come to the right place. Our converter simplifies the process of converting between epoch time
                                and human-readable date formats, making it easy for developers and enthusiasts alike.
                            </p>

                            <p>
                                Whether you need to convert a timestamp to a date or vice versa, our tool supports various time units,
                                including seconds, milliseconds, microseconds, and nanoseconds. The flexibility of our converter allows
                                you to work with different time resolutions.
                            </p>

                            <p>
                                Using our tool is straightforward. Simply enter the timestamp, choose the desired conversion, and
                                let the magic happen. Our converter provides not only the converted date but also additional information
                                such as GMT representation, local time, and a relative time description.
                            </p>

                            <p>
                                Stay organized and efficient in your time-related tasks with our Epoch Converter. It's a valuable
                                resource for developers working with epoch time in various applications. Save time and eliminate
                                conversion complexities with our user-friendly tool.
                            </p>
                        </div>
                    </div>

                </main>
            </div>
        );
    }

}

export default UnixConverter;