import React, { Component } from 'react';
import { Table, Form, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import DynamicModal from '../../../Form/GenericFields/DynamicModal';
import IframePreview from '../CreateInvoice/IframePreview';
import { connect } from 'react-redux';
import { exportToExcel } from '../../../Utils/exportToExcel';
import FirmDataEditDisaplay from './FirmDataEditDisaplay';
import ServiceDataEditDisaplay from './ServiceDataEditDisaplay';
import { createFirmDataRequest, deleteFirmDataRequest, fileInsertFirmDataRequest, getFirmDataRequest, updateFirmDataRequest } from '../../redux/actions/firmData.action';
import { MessageBanner } from '../../../Form/GenericFields/MessageBanner';
import { createServiceDataRequest, deleteServiceDataRequest, fileInsertServiceDataRequest, getServiceDataRequest, updateServiceDataRequest } from '../../redux/actions/serviceData.action';
import { read, utils } from 'xlsx';
import { BiSolidEditAlt, BiSolidTrash } from "react-icons/bi";

function mapStateToProps(state) {

  const { updateService, errorMessage, deletedService, updatedFirm, deletedFirm, uploadDataMessage } = state.masterDataModuleReducer.masterDataReducer;

  return { updateService, errorMessage, deletedService, updatedFirm, deletedFirm, uploadDataMessage };
}

const mapDispatchToProps = {
  createFirmDataRequest,
  createServiceDataRequest,
  updateServiceDataRequest,
  updateFirmDataRequest,
  getServiceDataRequest,
  getFirmDataRequest,
  deleteServiceDataRequest,
  deleteFirmDataRequest,
  fileInsertFirmDataRequest,
  fileInsertServiceDataRequest
}

class GenericTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      sortBy: '',
      sortOrder: 'asc',
      showUpdateModal: false,
      tableData: null,
      showCreateModal: false,
      type: null,
      successUpdate: false,
      successDelete: false,
      successCreate: false,
      showDeleteModal: false,
      deleteId: null
    };
  }

  handleClose = () => {
    this.setState({
      showUpdateModal: false, showCreateModal: false,
      successUpdate: false,
      successDelete: false,
      successCreate: false, showDeleteModal: false,
      deleteId: null
    });
  };

  handleCreateClose = () => {
    this.setState({ showCreateModal: false });
  }

  handleSearchChange = (event) => {
    this.setState({ searchTerm: event.target.value });
  };

  handleSort = (field) => {
    const { sortBy, sortOrder } = this.state;
    if (sortBy === field) {
      this.setState({ sortOrder: sortOrder === 'asc' ? 'desc' : 'asc' });
    } else {
      this.setState({ sortBy: field, sortOrder: 'asc' });
    }
  };

  handleExportToExcel = (filname) => {
    exportToExcel(this.props.data, filname);
  };

  handleEdit = (tableData, type) => {
    this.setState({ showUpdateModal: true, tableData, type })
  }

  handleDelete = (id, type) => {
    this.setState({ showDeleteModal: true, deleteId: id, type })
  }

  createNew = (type) => {
    this.setState({ showCreateModal: true, type })
  }

  onFirmSave = (formData, action) => {
    action === "insert" ?
      this.props.createFirmDataRequest(formData) :
      this.props.updateFirmDataRequest({ id: formData.id, input: formData });
  }

  onServiceSave = (formData, action) => {
    action === "insert" ?
      this.props.createServiceDataRequest(formData) :
      this.props.updateServiceDataRequest({ id: formData.id, input: formData });
  }

  deleteRow = () => {
    if (this.state.type === 'buyers') {
      this.props.deleteFirmDataRequest({ id: this.state.deleteId })
    } else {
      this.props.deleteServiceDataRequest({ id: this.state.deleteId })
    }
  }
  componentDidUpdate = (prevProps) => {
    if (prevProps.data !== this.props.data && this.state.showCreateModal) {
      this.setState({ showCreateModal: false, successCreate: true });
    }
    if ((prevProps.updateService !== this.props.updateService) && this.props.updateService && this.state.showUpdateModal) {
      this.setState({ showUpdateModal: false, successUpdate: true });
    }

    if ((prevProps.deletedService !== this.props.deletedService) && this.props.deletedService && this.state.showDeleteModal) {
      this.setState({ showDeleteModal: false, successDelete: true });
    }

    if ((prevProps.updatedFirm !== this.props.updatedFirm) && this.props.updatedFirm && this.state.showUpdateModal) {
      this.setState({ showUpdateModal: false, successUpdate: true });
    }

    if ((prevProps.deletedFirm !== this.props.deletedFirm) && this.props.deletedFirm && this.state.showDeleteModal) {
      this.setState({ showDeleteModal: false, successDelete: true });
    }

    if ((prevProps.uploadDataMessage !== this.props.uploadDataMessage) && this.props.uploadDataMessage) {
      this.setState({ successCreate: true });
    }
    // if (prevProps.errorMessage !== this.props.errorMessage && this.props.errorMessage ) {
    //   this.setState({ showUpdateModal: false, successUpdate: true });
    // }

  }

  handleExcelFileUpload = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    // Read the file content
    const buffer = await file.arrayBuffer();
    const workbook = read(buffer, { type: 'buffer' });

    // Extract data from the workbook
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    const excelData = utils.sheet_to_json(sheet);
    this.props.fileName.split('_')[0] === 'buyers' ?
      this.props.fileInsertFirmDataRequest({ input: excelData }) : this.props.fileInsertServiceDataRequest({ input: excelData });
    console.log("file data : ", excelData);
  }

  render() {
    const { data, headers, uploadDataMessage } = this.props;
    const { searchTerm, sortBy, sortOrder, showUpdateModal, type, successCreate, successDelete, successUpdate } = this.state;
    console.log(uploadDataMessage);
    // Filter and sort data based on search term, sortBy, and sortOrder
    const filteredData = Array.isArray(data)
      ? data.filter((item) =>
        Object.values(item).some((value) => String(value).toLowerCase().includes(searchTerm.toLowerCase()))
      )
      : [];

    // Apply sorting if sortBy field is specified
    if (sortBy) {
      filteredData.sort((a, b) => {
        const valueA = a[sortBy];
        const valueB = b[sortBy];
        if (sortOrder === 'asc') {
          return valueA > valueB ? 1 : -1;
        } else {
          return valueA < valueB ? 1 : -1;
        }
      });
    }

    return (
      <div>
        <h3 style={{paddingTop: '20px' , paddingBottom: '10px'}}>{this.props.fileName.replaceAll('_', ' ')}</h3>
        <Form className="mb-3">
          {successUpdate && <MessageBanner closeBtn={this.handleClose} messageType={'success'} messageContent={uploadDataMessage || "Updated Successfully"} />}
          {successDelete && <MessageBanner closeBtn={this.handleClose} messageType={'success'} messageContent={uploadDataMessage || "Deleted Successfully"} />}
          {successCreate && <MessageBanner closeBtn={this.handleClose} messageType={'success'} messageContent={uploadDataMessage ? uploadDataMessage : "Created Successfully"} />}
          {this.props.errorMessage && <MessageBanner closeBtn={this.handleClose} messageType={'warning'} messageContent={"Created Successfully"} />}


          {filteredData.length !== 0 && <Form.Control
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={this.handleSearchChange}
          />}
        </Form>
        {filteredData.length !== 0 && <Button variant="success" onClick={() => this.handleExportToExcel(this.props.fileName)}>Download to Excel</Button>}
        {"    "}
        <Button variant="success" onClick={() => this.createNew(this.props.fileName.split('_')[0].toLowerCase())}>Create New</Button>
        <div className="col-md-4">
          <input
            type="file"
            class="form-control"
            id="excelInput"
            accept=".xlsx, .xls , .csv"
            onChange={this.handleExcelFileUpload}
          />
        </div>
        <div className="table-container" style={{ overflowX: 'auto', maxWidth: '100%', maxHeight: '300px', marginBottom: '1rem' }}>
          {filteredData.length !== 0 && <Table striped bordered hover >
            <thead>
              <tr>
                <th>#</th> {/* Serial number column */}
                {headers.map((header, index) => (
                  <th key={index} onClick={() => this.handleSort(header.key)}>
                    {header.label}
                  </th>
                ))}
                <th className="last-column">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td> {/* Serial number */}
                  {headers.map((header, idx) => (
                    <td key={idx}>{item[header.key]}</td>
                  ))}
                  Copy code
<td className="last-column">
  {/* Buttons for Edit Details and Delete Item */}
  <div style={{ display: 'flex', gap: '5px' }}>
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id="edit-tooltip">Edit Details</Tooltip>}
    >
      <Button variant="info" size="sm" onClick={() => this.handleEdit(item, this.props.fileName.split('_')[0].toLowerCase())}>
        <BiSolidEditAlt />
      </Button>
    </OverlayTrigger>
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id="delete-tooltip">Delete Item</Tooltip>}
    >
      <Button variant='primary' size='sm' onClick={() => this.handleDelete(item.id, this.props.fileName.split('_')[0].toLowerCase())}>
        <BiSolidTrash />
      </Button>
    </OverlayTrigger>
  </div>
</td>
                </tr>
              ))}
            </tbody>
          </Table>}
          {filteredData.length === 0 && (
            <p style={{ textAlign: 'center', marginTop: '10px' }}>No data available</p>
          )}
        </div>
        <DynamicModal
          showModal={showUpdateModal}
          handleClose={this.handleClose}
          title={"Update " + this.props.fileName.replaceAll('_', ' ')}
          size="lg"
          body={this.props.fileName === 'Buyers_Detail' ?
            <FirmDataEditDisaplay
              initialValues={this.state.tableData}
              editableInt={true}
              onSave={this.onFirmSave}
              action={'update'}
              errorMessage={this.props.errorMessage} /> :
            <ServiceDataEditDisaplay
              initialValues={this.state.tableData}
              editableInt={true}
              onSave={this.onServiceSave}
              action={'update'} errorMessage={this.props.errorMessage} />}
          showButtons={false} // or false, depending on your requirement
        />

        <DynamicModal
          showModal={this.state.showCreateModal}
          handleClose={this.handleCreateClose}
          title={"Create " + this.props.fileName.replaceAll('_', ' ')}
          size="lg"
          body={this.state.type === 'buyers' ?
            <FirmDataEditDisaplay
              editableInt={true}
              onSave={this.onFirmSave}
              action={'insert'} errorMessage={this.props.errorMessage} /> :
            <ServiceDataEditDisaplay
              editableInt={true}
              onSave={this.onServiceSave}
              action={'insert'} errorMessage={this.props.errorMessage} />}
          showButtons={false} // or false, depending on your requirement
        />

        <DynamicModal
          showModal={this.state.showDeleteModal}
          handleClose={this.handleClose}
          title={"Create " + this.props.fileName.replace('_', ' ')}
          size="lg"
          body={
            <React.Fragment>
              <p>Sure you want to Delete?</p>
              {this.props.errorMessage && <p style={{ color: 'red' }}>{this.props.errorMessage}</p>}
            </React.Fragment>
          }
          showButtons={true} // or false, depending on your requirement
          primaryText={"Yes"}
          cancleText={"No"}
          handleSave={this.deleteRow}
          errorMessage={this.props.errorMessage}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GenericTable);
