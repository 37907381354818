import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import ReactToPrint from 'react-to-print';

const DynamicModal = ({ showModal, handleClose, title, body, size, showButtons , handleSave , primaryText, cancleText , showPrintButton}) => {
    return (
        <Modal show={showModal} onHide={handleClose} size={size}>
             <div className="modal-content" style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
             <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                <div className="row" >
                    <div className="col">
                        {body}
                    </div>
                </div>
            </Modal.Body>
            {showButtons && (
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {cancleText ? cancleText : "Close"}
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        {primaryText? primaryText : "Save"}
                    </Button>
                </Modal.Footer>
            )}
            {showPrintButton &&   <Modal.Footer>  <ReactToPrint  
                            trigger={() => <Button variant="primary" >
                                Print Invoice
                            </Button>} 
                            content={()=> document.getElementById('print-div')}
                            />
                            </Modal.Footer> 
            }
             </div>
        </Modal>
    );
};

export default DynamicModal;
