import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Field } from 'redux-form';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RenderedField, renderDate, renderSelect } from './RenderField';

class GenericFields extends Component {
    static propTypes = {
        name: PropTypes.string,
        label: PropTypes.string,
        className: PropTypes.string,
        autoComplete: PropTypes.string,
        validate: PropTypes.arrayOf(PropTypes.func),
        options: PropTypes.array,
        type: PropTypes.string,
        value: PropTypes.string,
        isDisabled: PropTypes.bool
    }

    static defaultProps = {
        type: 'text',
        options: [
            { value: 'india', label: 'India' },
            { value: 'usa', label: 'USA' }
        ],
        isDisabled: false,
        autoComplete: "off"
    }

    render() {
        const { type, isDisabled, ...rest } = this.props;

        return (
            <div>
                {type === 'select' ? (
                    <Field {...rest} component={renderSelect} disabled={isDisabled} />
                ) : type === 'date' ? (
                    <Field {...rest} component={renderDate} disabled={isDisabled}/>
                ) : (
                    <Field {...rest} component={RenderedField} disabled={isDisabled} type={type} />
                )}
            </div>
        );
    }
}

export default GenericFields;
