import React, { Component } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export const RenderedField = ({ input, label, type, className, value, options, disabled ,meta: { touched, error } }) => (
    <div>
        <label className="form-label"><strong>{label}</strong></label>
        <div>
           <input  {...input} type={type} className={className} disabled={disabled} autoComplete='off'/>
            {touched && error && <span className='int-login-error-text'>{error}</span>}
        </div>
    </div>
);

export const renderSelect = ({ input,type, label, disabled, options, meta: { touched, error } }) => (
    <div>
        <label className="form-label"><strong>{label}</strong></label>
        <div>
            <Select options={options} type={type} {...input} blurInputOnSelect={false}
            onBlur={(e) => {
                input.value = (!e.target.value) ? input.value : e.target.value;
            }}
            isDisabled={disabled}
            />
            {touched && error && <span className='int-login-error-text'>{error}</span>}
        </div>
    </div>
);


export const renderDate = ({ input, label, type, className, value, options, disabled, meta: { touched, error } }) => (
    <div>
        <label className="form-label"><strong>{label}</strong></label>
        <div>
            <DatePicker
                selected={input.value ? new Date(input.value) : null}
                onChange={input.onChange}
                onBlur={input.onBlur}
                className={className}
                disabled={disabled}
                placeholderText={input.placeholder}
            />
            {touched && error && <span className='int-login-error-text'>{error}</span>}
        </div>
    </div>
);
