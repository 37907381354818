import React, { Component } from 'react';
import { Button, Form, ProgressBar } from 'react-bootstrap';
import { FaChevronRight } from 'react-icons/fa';
import ProgressBarSteps from './ProgressBar';
import FirmMasterDisplay from '../../InvoiceCreation/components/CreateInvoice/FirmMasterDisplay';
import ServiceDataDisplay from '../../InvoiceCreation/components/CreateInvoice/ServiceDataDisplay';
import { FormWrapper } from './GenericWrappers';

class Wizard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: 1,
            steps: [
                { tittle: "Admin" },
                { tittle: "Buyers" },
                { tittle: "Products" },
                { tittle: "FinalPreview" },
            ],
            selectedOption: ''
        };
    }

    // Function to go to the next step
    nextStep = () => {
        const { currentStep } = this.state;
        if (currentStep < 4) {
            this.setState({
                currentStep: currentStep + 1
            });
        }
    };

    // Function to go to the previous step
    prevStep = () => {
        const { currentStep } = this.state;
        if (currentStep > 1) {
            this.setState({
                currentStep: currentStep - 1
            });
        }
    };

    handleSelectChange = (event) => {
        this.props.setBuyerFirmDataRequest(this.props.firmData.filter(e => e.firm_name === event.target.value)[0])
        this.setState({ selectedOption: event.target.value });
    };

    render() {
        const { currentStep, steps, selectedOption } = this.state;
        return (
            <div className="container text-center">
                <h2 className="mb-4">Create Invoice</h2>
                <ProgressBar now={(currentStep - 1) * 25} label={`${(currentStep - 1) * 25}%`} className="mb-4" />

                <div className="container mt-5">
                    <ProgressBarSteps currentStep={currentStep} steps={steps} />
                </div>
                {/* Form Content for each step */}
                {currentStep === 1 && (<>
                    <FormWrapper showNav={false} navTittle={"Admin Details"} parrentTab={""} childrens={[]}>
                      
                    <Form.Group controlId="step1">
                        {this.props.addedAdminFirmData && <FirmMasterDisplay initialValues={this.props.addedAdminFirmData} />}
                    </Form.Group>
                    </FormWrapper>
                </>
                )}

                {currentStep === 2 && (
                    <FormWrapper showNav={false} navTittle={"Select Buyer"} parrentTab={""} childrens={[]}>
                        <Form.Group controlId="step2">
                            <Form.Control as="select" value={selectedOption} onChange={this.handleSelectChange} required>
                                <option value="">Select a buyer...</option>
                                {this.props.firmData.filter(e => e.role === 'buyer').map((item, index) => (<>
                                    <option value={item.firm_name}>{item.firm_name}</option>
                                </>))}

                            </Form.Control>
                            {this.props.addedBuyerFirmData && selectedOption && <FirmMasterDisplay initialValues={this.props.addedBuyerFirmData} />}

                        </Form.Group>
                    </FormWrapper>
                )}

                {currentStep === 3 && (
                    <Form.Group controlId="step3">
                        <Form.Label>Step 3: Payment Information</Form.Label>
                        {this.props.serviceData && <ServiceDataDisplay serviceData={this.props.serviceData} />}
                    </Form.Group>
                )}

                {currentStep === 4 && (
                    <Form.Group controlId="step4">
                        <Form.Label>Step 4: Review and Confirm</Form.Label>
                        <Form.Text className="text-muted">Please review your information before submitting.</Form.Text>
                    </Form.Group>
                )}

                {/* Navigation Buttons */}
                <div className="mt-4">
                    {currentStep !== 1 && (
                        <Button variant="secondary" onClick={this.prevStep}>
                            Previous
                        </Button>
                    )}
                    {currentStep !== 4 ? (
                        <Button variant="primary" onClick={this.nextStep}>
                            Next
                        </Button>
                    ) : (
                        <Button variant="success">Submit</Button>
                    )}
                </div>
            </div>
        );
    }
}

export default Wizard;
