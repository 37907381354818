import React, { Component } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import PropTypes from 'prop-types';
import { Button} from 'react-bootstrap';

// Validation function for form fields
const validate = (values) => {
  const errors = {};

  if (!values.firm_name) {
    errors.firmName = 'Firm name is required';
  }

  if (!values.firm_address) {
    errors.address = 'Address is required';
  }

  if (!values.gstin_number) {
    errors.gstin_number = 'GSTIN is required';
  } else if (values.gstin_number.length !== 15) {
    errors.gstin_number = 'GSTIN must be 15 characters';
  }

  return errors;
};

class FirmMasterDisplay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
      firm_name: props.initialValues.firm_name || '',
      firm_address: props.initialValues.firm_address || '',
      gstin_number: props.initialValues.gstin_number || ''
    };
  }

  onSubmit = (formData) => {
    // Simulate form submission (e.g., API call)
    // if (formData.gstin.substring(0, 2) !== '12') {
    //   throw new SubmissionError({
    //     gstin: 'Invalid State Code (first two digits of GSTIN must be "12")'
    //   });
    // }
    // Reset form after submission
    // this.props.reset(); // If using reduxForm, uncomment this line
    this.setState({ isEditing: false }); // Disable editing after submission
  };

  render() {
    const { handleSubmit, pristine, submitting, error } = this.props;
    const { isEditing, firm_name, firm_address, gstin_number } = this.state;
    return (
      <div className="">
        {/* <h2 className="text-center mb-4">Firm Details Form</h2> */}
    {firm_name && firm_address&& gstin_number && <form onSubmit={handleSubmit(this.onSubmit)} key={firm_name}>
        <div className="row justify-content-center">
        <div className="col-md-12">
            <div className="mb-3">
            <label htmlFor="firm_name" className="form-label">
                Firm Name
            </label>
            <Field
                name="firm_name"
                component="input"
                type="text"
                className="form-control"
                placeholder="Enter firm name"
                disabled={!isEditing}
            />
            </div>
            <div className="mb-3">
            <label htmlFor="firm_address" className="form-label">
                Address
            </label>
            <Field
                name="firm_address"
                component="textarea"
                className="form-control"
                placeholder="Enter address"
                disabled={!isEditing}
            />
            </div>
            <div className="mb-3">
            <label htmlFor="gstin_number" className="form-label">
                GSTIN
            </label>
            <Field
                name="gstin_number"
                component="input"
                type="text"
                className="form-control"
                placeholder="Enter GSTIN"
                disabled={!isEditing}
            />
            {error && <div className="text-danger mt-1">{error}</div>}
            </div>
        </div>
        </div>
        {/* <div className="text-center mb-3">
        {!isEditing && (
            <button
            type="button"
            className="btn btn-secondary me-3"
            onClick={() => this.setState({ isEditing: true })}
            >
            Edit <i className="bi bi-pencil-fill"></i>
            </button>
        )}
        {isEditing && (
            <button
            type="submit"
            className="btn btn-primary"
            disabled={pristine || submitting}
            >
            {submitting ? 'Saving...' : 'Save'}
            </button>
        )}
        </div> */}
                    <div className="d-flex gap-2 justify-content-center py-5">
                        {this.props.currentStep !== 1 && (
                            <Button variant="secondary" onClick={this.props.prevStep}>
                                Previous
                            </Button>
                        )}
                        {this.props.currentStep !== 4 ? (
                            <Button variant="primary" onClick={this.props.nextStep}>
                                Next
                            </Button>
                        ) : (
                            <Button variant="success">Submit</Button>
                        )}
                    </div>
    </form>}
      </div>
    );
  }
}

FirmMasterDisplay.propTypes = {
  initialValues: PropTypes.array.isRequired, // Assuming initialValues is an object
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string
};

export default reduxForm({
  form: 'firmDetails', // Unique identifier for this form
  validate,
  enableReinitialize:true,
  destroyOnUnmount: false
})(FirmMasterDisplay);
