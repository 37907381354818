export const getPDFBase64Schema = ( payload ) => {
    const {
      adminFirmAddress,
      adminFirmName,
      adminFirmState,
      adminFirmStateCode,
      adminGstNumber,
      bankAccountNumber,
      bankBranchAddress,
      bankBranchName,
      bankIFSCCode,
      buyerFirmAddress,
      buyerFirmName,
      buyerFirmState,
      buyerFirmStateCode,
      buyerGstNumber,
      invoiceId,
      productDetails,
      roundOffTotal,
      subTotalGross,
      subTotalTax,
      subTotalNet,
      subTotalNetInWords,
      logo
    } = payload;
  
    // Construct productDetails string based on the array of objects
    const productDetailsString = productDetails.map((detail) => {
      return `
        {
          cgst: ${detail.cgst},
          hsn: "${detail.hsn}",
          igst: ${detail.igst},
          netAmount: ${detail.netAmount},
          product_name: "${detail.product_name}",
          quantity: ${detail.quantity},
          rate: ${detail.rate},
          sgst: ${detail.sgst},
          totalGross: ${detail.totalGross},
          totalTax: ${detail.totalTax}
        }
      `;
    }).join(',\n');
  
    // Construct the GraphQL mutation schema string with dynamic values
    const schema = `
      mutation  GetBase64Pdf {
        getBase64Pdf(input: {
          adminFirmAddress: "${adminFirmAddress}",
          adminFirmName: "${adminFirmName}",
          adminFirmState: "${adminFirmState}",
          adminFirmStateCode: "${adminFirmStateCode}",
          adminGstNumber: "${adminGstNumber}",
          bankAccountNumber: "${bankAccountNumber}",
          bankBranchAddress: "${bankBranchAddress}",
          bankBranchName: "${bankBranchName}",
          bankIFSCCode: "${bankIFSCCode}",
          buyerFirmAddress: "${buyerFirmAddress}",
          buyerFirmName: "${buyerFirmName}",
          buyerFirmState: "${buyerFirmState}",
          buyerFirmStateCode: "${buyerFirmStateCode}",
          buyerGstNumber: "${buyerGstNumber}",
          invoiceId: "${invoiceId}",
          productDetails: [
            ${productDetailsString}
          ],
          roundOffTotal: ${roundOffTotal},
          subTotalGross: ${subTotalGross},
          subTotalTax: ${subTotalTax},
          subTotalNet: ${subTotalNet},
          subTotalNetInWords: "${subTotalNetInWords}"
          logo: "${logo}"
        }) 
      }
    `;
  
    return schema;
  };
  