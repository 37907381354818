export const deleteServiceDataSchema = (payload)=>`
mutation deleteServiceData {
    deleteServiceData(id:${payload.id}) {
     message
     updatedServiceData{ 
       id
      product_name
       hsn_sac
       rate_per_count
       gstin_percentage
       district
       season
       year}
   }
 }
 
`