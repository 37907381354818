import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { reduxForm } from 'redux-form';
import { validateIsRequired } from '../../../Form/GenericFields/validation';
import { FormWrapper, ProfileFormWrapper } from '../../../Form/GenericFields/GenericWrappers';
import GenericFields from '../../../Form/GenericFields/GenericFields';

const EditableFirmForm = ({ handleSubmit, initialValues, editableInt ,onSave ,action, errorMessage}) => {
    const [editable, setEditable] = useState(editableInt);
    const [formData, setFormData] = useState(initialValues);
    const toggleEdit = () => {
        setEditable(!editable);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const onSubmit = (e) => {
        //e.preventDefault(); // Prevent default form submission
        onSave(formData, action);
        setEditable(!editable);
        // You can perform additional actions here, such as dispatching an action to save the form data
    };
    return (
       
        // <FormWrapper showNav={false} navTittle={"Service"} parrentTab={""} childrens={[]}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-outline mb-4">
                        <GenericFields name="firm_name" label="Firm Name" onChange={handleInputChange} className="form-control" isDisabled={!editable} validate={[validateIsRequired]} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-outline mb-4">
                        <GenericFields name="firm_address" label="Address" onChange={handleInputChange} className="form-control" isDisabled={!editable} validate={[validateIsRequired]} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-outline mb-4">
                        <GenericFields name="owner_name" label="Owner Name" onChange={handleInputChange} className="form-control" isDisabled={!editable} validate={[validateIsRequired]} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-outline mb-4">
                        <GenericFields name="pincode" label="Pincode" onChange={handleInputChange} className="form-control" isDisabled={!editable} validate={[validateIsRequired]} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-outline mb-4">
                        <GenericFields name="gstin_number" label="GSTIN Number" onChange={handleInputChange} className="form-control" isDisabled={!editable} validate={[validateIsRequired]} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-outline mb-4">
                        <GenericFields name="state" label="State" onChange={handleInputChange} className="form-control" isDisabled={!editable} validate={[validateIsRequired]} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-outline mb-4">
                        <GenericFields name="state_code" label="State Code" onChange={handleInputChange} className="form-control" isDisabled={!editable} validate={[validateIsRequired]} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-outline mb-4">
                        <GenericFields name="bank_name" label="Bank Name" onChange={handleInputChange} className="form-control" isDisabled={!editable} validate={[validateIsRequired]} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-outline mb-4">
                        <GenericFields name="bank_account_number" label="Bank Account Number" onChange={handleInputChange} className="form-control" isDisabled={!editable} validate={[validateIsRequired]} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-outline mb-4">
                        <GenericFields name="bank_branch" label="Bank Branch" onChange={handleInputChange} className="form-control" isDisabled={!editable} validate={[validateIsRequired]} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-outline mb-4">
                        <GenericFields name="bank_ifsc" label="Bank IFSC" onChange={handleInputChange} className="form-control" isDisabled={!editable} validate={[validateIsRequired]} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-outline mb-4">
                        <GenericFields name="pan_company" label="Company Pan" onChange={handleInputChange} className="form-control" isDisabled={!editable} validate={[validateIsRequired]} />
                    </div>
                </div>
            </div>
            {errorMessage && <p style={{color: "red"}}>{errorMessage}</p>}
            {/* Render other form fields similarly */}

            <Button variant="primary" type="submit" disabled={!editable}> {/* Use type="submit" */}
                Save
            </Button>
            {
                !editableInt && <Button variant="secondary" onClick={toggleEdit}>
                    {editable ? 'Cancel' : 'Edit'}
                </Button>
            }
      </form>
    //   </FormWrapper>
    );
};

// Wrap the form component with reduxForm
export default reduxForm({
    form: 'editableFirmForm'
})(EditableFirmForm);
