export const updateFirmDataSchema = (payload) => `
mutation UpdateFirmMaster {
    updateFirmMaster(id: ${payload.id},input: {
     firm_name: "${payload.input.firm_name}",
     firm_address: "${payload.input.firm_address}",
     owner_name: "${payload.input.owner_name}",
     pincode: "${payload.input.pincode}",
     gstin_number: "${payload.input.gstin_number}",
     state: "${payload.input.state}",
     state_code: "${payload.input.state_code}",
     bank_name: "${payload.input.bank_name}",
     bank_account_number: "${payload.input.bank_account_number}",
     bank_branch: "${payload.input.bank_branch}",
     bank_ifsc: "${payload.input.bank_ifsc}",
     pan_company: "${payload.input.pan_company}",
     role: "${payload.input.role}"
   }) {
    message
    updatedFirmData{
      id
       firm_name
    firm_address
    owner_name
    pincode
    gstin_number
    state
    state_code
    bank_name
    bank_account_number
    bank_branch
    bank_ifsc
    pan_company
    role
    }
   }
 }
`