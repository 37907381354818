export const GET_PRINTER_DETAILS_REQUEST ='GET_PRINTER_DETAILS_REQUEST';
export const GET_PRINTER_DETAILS_REQUEST_SUCCESS ='GET_PRINTER_DETAILS_REQUEST_SUCCESS';
export const GET_PRINTER_DETAILS_REQUEST_FAILURE ='GET_PRINTER_DETAILS_REQUEST_FAILURE';

export const getPrinterDetailsRequest = () => ({
    
    type: GET_PRINTER_DETAILS_REQUEST
}); 

export const getPrinterDetailsRequestSuccess = (payload) => ({
    type: GET_PRINTER_DETAILS_REQUEST_SUCCESS, payload
}); 

export const getPrinterDetailsRequestFailure = (error) => ({
    type: GET_PRINTER_DETAILS_REQUEST_FAILURE , error
}); 