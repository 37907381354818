export const GET_SERVICE_DATA_REQUEST ='GET_SERVICE_DATA_REQUEST';
export const GET_SERVICE_DATA_REQUEST_SUCCESS ='GET_SERVICE_DATA_REQUEST_SUCCESS';
export const GET_SERVICE_DATA_REQUEST_FAILURE ='GET_SERVICE_DATA_REQUEST_FAILURE';
export const SET_ADDED_SERVICE_DATA_REQUEST='SET_ADDED_SERVICE_DATA_REQUEST';
export const UPDATE_ADDED_SERVICE_DATA = 'UPDATE_ADDED_SERVICE_DATA';
export const CREATE_SERVICE_DATA_REQUEST ='CREATE_SERVICE_DATA_REQUEST';
export const CREATE_SERVICE_DATA_REQUEST_SUCCESS ='CREATE_SERVICE_DATA_REQUEST_SUCCESS';
export const CREATE_SERVICE_DATA_REQUEST_FAILURE ='CREATE_SERVICE_DATA_REQUEST_FAILURE';
export const UPDATE_SERVICE_DATA_REQUEST ='UPDATE_SERVICE_DATA_REQUEST';
export const UPDATE_SERVICE_DATA_REQUEST_SUCCESS ='UPDATE_SERVICE_DATA_REQUEST_SUCCESS';
export const UPDATE_SERVICE_DATA_REQUEST_FAILURE ='UPDATE_SERVICE_DATA_REQUEST_FAILURE';
export const DELETE_SERVICE_DATA_REQUEST ='DELETE_SERVICE_DATA_REQUEST';
export const DELETE_SERVICE_DATA_REQUEST_SUCCESS ='DELETE_SERVICE_DATA_REQUEST_SUCCESS';
export const DELETE_SERVICE_DATA_REQUEST_FAILURE ='DELETE_SERVICE_DATA_REQUEST_FAILURE';
export const FILE_INSERT_SERVICE_DATA_REQUEST ='FILE_INSERT_SERVICE_DATA_REQUEST';
export const FILE_INSERT_SERVICE_DATA_REQUEST_SUCCESS ='FILE_INSERT_SERVICE_DATA_REQUEST_SUCCESS';
export const FILE_INSERT_SERVICE_DATA_REQUEST_FAILURE ='FILE_INSERT_SERVICE_DATA_REQUEST_FAILURE';

export const getServiceDataRequest = (payload) => ({
    type: GET_SERVICE_DATA_REQUEST,payload
}); 

export const getServiceDataRequestSuccess = (payload) => ({
    type: GET_SERVICE_DATA_REQUEST_SUCCESS, payload
}); 

export const getServiceDataRequestFailure = (error) => ({
    type: GET_SERVICE_DATA_REQUEST_FAILURE , error
}); 

export const setAddedServiceDataRequest = (payload) => ({
    type: SET_ADDED_SERVICE_DATA_REQUEST , payload
});

export const updateAddedServiceDataRequest = (payload) => ({
    type: UPDATE_ADDED_SERVICE_DATA , payload
});


export const createServiceDataRequest = (payload) => ({
    type: CREATE_SERVICE_DATA_REQUEST,payload
}); 

export const createServiceDataRequestSuccess = (payload) => ({
    type: CREATE_SERVICE_DATA_REQUEST_SUCCESS, payload
}); 

export const createServiceDataRequestFailure = (error) => ({
    type: CREATE_SERVICE_DATA_REQUEST_FAILURE , error
}); 

export const updateServiceDataRequest = (payload) => ({
    type: UPDATE_SERVICE_DATA_REQUEST,payload
}); 

export const updateServiceDataRequestSuccess = (payload) => ({
    type: UPDATE_SERVICE_DATA_REQUEST_SUCCESS, payload
}); 

export const updateServiceDataRequestFailure = (error) => ({
    type: UPDATE_SERVICE_DATA_REQUEST_FAILURE , error
});

export const deleteServiceDataRequest = (payload) => ({
    type: DELETE_SERVICE_DATA_REQUEST,payload
}); 

export const deleteServiceDataRequestSuccess = (payload) => ({
    type: DELETE_SERVICE_DATA_REQUEST_SUCCESS, payload
}); 

export const deleteServiceDataRequestFailure = (error) => ({
    type: DELETE_SERVICE_DATA_REQUEST_FAILURE , error
});
export const fileInsertServiceDataRequest = (payload) => ({
    type: FILE_INSERT_SERVICE_DATA_REQUEST,payload
}); 

export const fileInsertServiceDataRequestSuccess = (payload) => ({
    type: FILE_INSERT_SERVICE_DATA_REQUEST_SUCCESS, payload
}); 

export const fileInsertServiceDataRequestFailure = (error) => ({
    type: FILE_INSERT_SERVICE_DATA_REQUEST_FAILURE , error
});  