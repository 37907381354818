
export const validateEmail = (value) => {
    if (!value) {
        return 'Field is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
        return 'Invalid email address';
    }
    return undefined; // No validation errors
};

export const validateName = (name) => {
    let error = '';

    if (!name) {
        error = 'Field is required';
    } else if (!/^[A-Za-z\s]+$/.test(name)) {
        error = 'Invalid characters';
    }

    return error;
};
export const validatePasswordMatch = (value, allValues) => {
    if (value !== allValues.userPassword) {
        return 'Passwords do not match';
    }
    return undefined;
};

export const validateIsRequired = (value) => {
    if (!value) {
        return 'Field is required'; // Error message for required validation
    }
    return undefined;
};

export const validateIsOnlyNumber = (value) => {
    
    if (!value) {
        return 'Field is required';
    } else if (isNaN(value)) {
       return 'Field must be a number';
    }
    return undefined;
}
export const validateAmount = (value, allValues, props) => {
   
    const maxAmount = props.invoiceRowtoUpdate.subTotalNet;
    if (value > maxAmount) {
        return `Amount should not exceed ${maxAmount}`;
    }
    return undefined;
};