import { call, put } from "redux-saga/effects";
import { checkTokenExpiration } from "./authUser";


function* sagaHandle(fn,schema ,onSuccess, onFailure, withoutToken) {
    try {
        if (!withoutToken) {
            checkTokenExpiration();
        }
        
    
        const {data} = yield call(fn, schema, withoutToken);

        if (data && onSuccess) {
            yield put(onSuccess(data));
            return data;
        }
    } catch (error) {
        if (onFailure) {
            console.log("saga handle",  error)
            yield put(onFailure({
                error: error,
                errorCodes: "",
            }));
        }
    
    }
}

export {
    sagaHandle  
}