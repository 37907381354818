import { call, put, takeLatest } from "redux-saga/effects";
import { sagaHandle } from "../../../Utils/sagaDataHandling";
import { Query } from "../../../../api/graphql.config";
import { GET_PRINTER_DETAILS_REQUEST, getPrinterDetailsRequestFailure, getPrinterDetailsRequestSuccess } from "../actions/settings.action";
import { getPrinterDetailsSchema } from "../schemas/settings.schema";

export function* getPrinterDetailsRequestSaga() {
    try {
         yield call(
           sagaHandle,
            Query,
            getPrinterDetailsSchema(),
           getPrinterDetailsRequestSuccess,
         getPrinterDetailsRequestFailure
        )
    } catch (error) {
        console.log(error)
    }
}

export default function* settingsSagas() {
    yield takeLatest(GET_PRINTER_DETAILS_REQUEST, getPrinterDetailsRequestSaga)
}